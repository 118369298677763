<script>
  import { _, locale } from 'svelte-i18n';
  import { createEventDispatcher } from 'svelte';
  import { get } from 'svelte/store';
  import logger from '../../../js/logger';
  import { hotkey } from '../../../js/actions';
  import { rightToLeft, 
           booking,
           seatPaymentAmountPerPassenger 
         } from '../../../js/stores';
  import { currencyFormat } from '../../../js/utils';

  import Button from '../Button.svelte';

  export let closeMenuHandler = () => {};
  export let passenger = null;
  export let seat = null;

  const dispatch = createEventDispatcher();

  /** Handle the Confirm button. */
  function selectSeatHandler() {
    if (seat.isPaid() && seat.isSeatAmountGreatorThanZero()) {
      
      let isPaymentWarningAccepted = false;

      get(booking).passengers.forEach(p => {
      if (p.passengerID === passenger.passengerID && p.seatSelectionPaymentAccepted) {
        isPaymentWarningAccepted = true;
      }
    });

      if (isPaymentWarningAccepted === true) {
        logger.info('Payment warning has already been accepted. Just call allocateSeat');
        
        dispatch('updateSelectedSeat', {
          passenger,
          seat: String(seat),
          amount: seat.getPrice()
        });

        // update the seat price per passenger object
        updateSeatPaymentStore(passenger, seat.getPrice(), String(seat));
      } else {
        dispatch('openPaymentWarningModal');
      }
    } else {
        // update the seat price per passenger object
        updateSeatPaymentStore(passenger, 0, String(seat));

        dispatch('updateSelectedSeat', {
          passenger,
          seat: String(seat),
          amount: 0
        });
    }
  }

  function updateSeatPaymentStore(passenger, seatAmount, seatNumber) {
    let seatPaymentAmount = get(seatPaymentAmountPerPassenger);
    if (seatPaymentAmount && seatPaymentAmount?.length > 0) {
      let isPassengerFound = false;
      seatPaymentAmount.forEach(s => {
        if (s.passengerID === passenger.passengerID) {
          isPassengerFound = true;
          s.amount = seatAmount; //seat.getPrice()
        }
      });
      if (!isPassengerFound && seatAmount > 0) {
        seatPaymentAmount.push({ passengerID: passenger.passengerID, amount: seat.getPrice(), seatNumber: seatNumber });
      }
      seatPaymentAmountPerPassenger.set(seatPaymentAmount);
    } else if (seatAmount > 0) {
      seatPaymentAmountPerPassenger.set([{ passengerID: passenger.passengerID, amount: seatAmount, seatNumber: seatNumber }]);  
    }
  }
</script>

<div class="flex {$rightToLeft ? 'flex-row-reverse' : ''} items-end">
  <button
    class="{$rightToLeft ? 'mr-7' : 'mx-7'} text-gold-100 uppercase underline"
    role="button"
    use:hotkey
    on:click={closeMenuHandler}
  >
    {$_('seatMap.goBack')}
  </button>

  <div class="flex flex-col">
    {#if seat.isPaid()}
      <p class="font-AltisMedium mb-1 text-2xl">
        {currencyFormat($locale, seat.getCurrencyCode(), seat.getPrice())}
      </p>
    {/if}

    {#if seat.hasPassengerPaid(passenger)}
      <p class="font-AltisMedium mb-1 text-2xl">
        {$_('seatMap.additionalChargesMayApply')}
      </p>
    {/if}

    <Button class="px-8" size="small" on:click={selectSeatHandler}>
      {$_('seatMap.confirmSeat')}
    </Button>
  </div>
</div>
