/** FlightDeck payload builders. */
import { get } from 'svelte/store';
import { booking, getKioskName, headPassenger, pnr } from '../../stores';
import constants from './const.js';
import { WEIGHT_UNITS } from '../../../js/const';

const {
  DEVICE_TYPE,
  TransactionStatuses,
  TransactionSubTypes,
  TransactionTypes,
} = constants;

/**
 * Extract the 'bagDropdata' object from a payload.
 *
 * @param {object} payload - A payload as created by a method of payloads.
 * @returns {object} - The bagDropData object extracted from the payload.
 */
function extractBagDropData(payload) {
  return (payload['data'] || {})['bagDropData'] || {};
}

/**
 * Adds common fields to the payload.
 *
 * @param {object} payload - Additional payload fields.
 * @param {object} booking - The booking object from Switchboard.
 * @param {object} passenger - The passenger object from Switchboard.
 * @returns {object} The constructed flightdeck payload.
 */
function basePayload(payload, _booking = null, _passenger = null) {
  const bookingData = _booking || get(booking),
    passenger = _passenger || get(headPassenger),
    { message } = payload;

  return {
    nodeId: getKioskName(),
    deviceType: DEVICE_TYPE,
    pnr: bookingData.bookingReference || get(pnr),
    passengerId: passenger.passengerID,
    passengerFirstName: passenger.firstName,
    passengerLastName: passenger.lastName,
    ...payload,
  };
}

function makeSimpleAlert(message) {
  return () => {
    return basePayload({
      message,
      transactionType: TransactionTypes.Alert,
      transactionSubTypes: [TransactionSubTypes.Unknown],
    });
  };
}

/** Get bag data */
function getBagData(bag, bagIndex) {
  return {
    bagDropData: {
      bagTagId: bag.bagTagID,
      bagWeight: bag.weight,
      bagIndex,
      bagHeight: bag.height,
      bagWidth: bag.width,
      bagLength: bag.length,
    },
  };
}

const payloads = {
  /** Bag exceeds maximum weight limit of a single bag. EYSS-658. */
  overweightSingleBag(bag, bagIndex, exceededBy, bagWeightMax) {
    return basePayload({
      message:
        `Maximum single bag weight exceeded - ` +
        `bag ${bagIndex}. ` +
        `The weight of the overweight bag is ${bag.weight}${WEIGHT_UNITS}.`,
      transactionType: TransactionTypes.Alert,
      transactionSubTypes: [TransactionSubTypes.OverweightBaggage],
      data: {
        bagDropData: {
          bagTagId: bag.bagTagID,
          bagWeight: bag.weight,
          bagIndex,
          exceededBy,
        },
      },
    });
  },

  /** Too many bags */
  tooManyBags(bagIndex) {
    return basePayload({      
      message: `Multiple bags are placed on the belt. Please place one bag at a time on the belt. bag number: ${bagIndex}.`,        
      transactionType: TransactionTypes.Alert,
      transactionSubTypes: [TransactionSubTypes.Unknown]
    });
  },

  /** Passenger declines weight upgrade payment when over limit. EYSS-1290. */
  declinedWeightUpgrade(
    bag,
    bagIndex,
    bagCount,
    exceededBy,
    excessWeightCost,
    currency,
  ) {
    return basePayload({
      message:
        ` Bag ${bagIndex}/${bagCount} - ${bag.weight}${WEIGHT_UNITS}. Total allowance exceeded by ${exceededBy}${WEIGHT_UNITS}.` +
        ` Passenger declined payment of ${excessWeightCost} ${currency} for excess weight.`,
      transactionType: TransactionTypes.Actionable,
      transactionSubTypes: [
        TransactionSubTypes.OverweightBaggage,
        TransactionSubTypes.TotalBaggageExceeded,
        TransactionSubTypes.ExcessBaggagePaymentDeclined,
      ],
      data: {
        bagDropData: {
          bagWeight: bag.weight,
          bagIndex,
          bagCount,
          exceededBy,
          excessWeightCost,
          currency,
        },
      },
    });
  },

  declinedSeatPayment() {
    return basePayload({
      message: ` Passenger declined to pay for seat change on Barcode scan screen.`,
      transactionType: TransactionTypes.Actionable,
      transactionSubTypes: [
        TransactionSubTypes.OverweightBaggage, // using bag const for seat since we cant modify flight deck code
        TransactionSubTypes.TotalBaggageExceeded,// using bag const for seat since we cant modify flight deck code
        TransactionSubTypes.ExcessBaggagePaymentDeclined,// using bag const for seat since we cant modify flight deck code
      ]
    });
  },

  /**
   * Passenger declines weight upgrade payment when approaching limit.
   * EYSS-1279.
   */
  declinedWeightWarning(
    bagWeightWarningPercent,
    bag,
    bagIndex,
    excessWeightCost,
    currency,
  ) {
    return basePayload({
      message: bagWeightWarningPercent + '% allowance reached',
      transactionType: TransactionTypes.Actionable,
      transactionSubTypes: [
        TransactionSubTypes.OverweightBaggage,
        TransactionSubTypes.TotalWeightPercentageExceeded,
      ],
      data: {
        bagDropData: {
          bagWeight: bag.weight,
          bagIndex,
          excessWeightCost,
          currency,
        },
      },
    });
  },

  /** Dimensions exceed maximums. EYSS-288, EYSS-753. */
  oversizeBag(bag, bagIndex) {
    return basePayload({
      message: `Baggage exceeds accepted baggage dimensions. Bag number ${bagIndex}.`,
      transactionType: TransactionTypes.Alert,
      transactionSubTypes: [TransactionSubTypes.OversizedBaggage],
      data: getBagData(bag, bagIndex),
    });
  },

  /** CUSS dimension errors and other similar CUSS errors. EYSS-2447. */
  cussDimensionError(bag, bagIndex, errorDescription) {
    return basePayload({
      message: `${errorDescription}. Bag number ${bagIndex}. The bag should be removed from the belt and placed properly.`,
      transactionType: TransactionTypes.Alert,
      transactionSubTypes: [TransactionSubTypes.Unknown],
      data: getBagData(bag, bagIndex),
    });
  },

  /** CUSS BAGGAGE_NOT_CONVEYABLE. EYSS-2820. */
  bagNotConveyable(bag, bagIndex) {
    return basePayload({
      message:
        'Bag is not conveyable.' +
        ' Probable cause(s): irregular bag (e.g. strollers).' +
        ` Bag number ${bagIndex}.`,
      transactionType: TransactionTypes.Alert,
      transactionSubTypes: [TransactionSubTypes.Unknown],
      data: getBagData(bag, bagIndex),
    });
  },

  /** General booking retrieval failures. EYSS-625. */
  bookingNotFound() {
    return basePayload({
      message: 'Booking not found',
      transactionType: TransactionTypes.Alert,
      transactionSubTypes: [TransactionSubTypes.BookingNotFound],
    });
  },

  /** Failed printing a bag tag. EYSS-656. */
  bagTagPrintFailure(bagTagId) {
    return basePayload({
      message: 'Bag Tag Print failure',
      transactionType: TransactionTypes.Alert,
      TransactionSubTypes: [TransactionSubTypes.PrintError],
      data: {
        bagDropData: {
          bagTagId,
        },
      },
    });
  },

  /** ATB print failure. EYSS-657. */
  atbPrintFailure() {
    return basePayload({
      message: 'ATB print failure.',
      transactionType: TransactionTypes.Alert,
      transactionSubTypes: [TransactionSubTypes.PrintError],
    });
  },

  /** Scanned Bag Tag is not a valid bag tag. EYSS-289, EYSS-802. */
  incorrectBagTagNumber(bag, bagIndex) {
    return basePayload({
      message: 'Incorrect bag tag number.',
      transactionType: TransactionTypes.Alert,
      transactionSubTypes: [TransactionSubTypes.InvalidBagTag],
      data: {
        bagDropData: {
          bagTagId: bag.bagTagID,
          bagWeight: bag.weight,
          bagIndex,
        },
      },
    });
  },

  missingBagTag(bag, bagIndex) {
    return basePayload({
      message: 'Missing bag tag.',
      transactionType: TransactionTypes.Alert,
      transactionSubTypes: [TransactionSubTypes.InvalidBagTag],
      data: {
        bagDropData: {
          bagTagId: bag.bagTagID,
          bagWeight: bag.weight,
          bagIndex,
        },
      },
    });
  },

  timeoutRecoveryFailure(documentName) {
    return basePayload({
      message: `Timeout recovery failed - ${documentName} mismatch.`,
      transactionType: TransactionTypes.Alert,
      transactionSubTypes: [TransactionSubTypes.TravelDocument],
    });
  },

  /** Dangerous goods declaration declined. EYSS-291. */
  dangerousGoods() {
    return basePayload({
      message: 'DGR has not been accepted.',
      transactionType: TransactionTypes.Actionable,
      transactionSubTypes: [TransactionSubTypes.DGR],
    });
  },

  seatChangeChargesNotFound() {
    return basePayload({
      message: 'Passenger has choosen a chargeable but application did not get Unpaid Items.',
      transactionType: TransactionTypes.Actionable,
      transactionSubTypes: [TransactionSubTypes.DGR], // we can not make changes to flight deck code base and can not redeploy flightdeck hence using existing actionable type
    });
  },

  /** Bag tag not readable. EYSS-2392. */
  conveyorNotReadable: makeSimpleAlert('Bag tag has not been accepted.'),

  excessBaggageRateFailed: makeSimpleAlert('Could not retrieve excess baggage rates. Transaction ended'),

  markBoardingPassPrintAPIFailure: makeSimpleAlert('The boarding passes could not be marked as printed.'),

  seatChangePaymentSuccessful: makeSimpleAlert('The seat change payment has been successfully made.'),

  /** Bag tag not readable final attempt. EYSS-2392. */
  conveyorNotReadableFinalAttempt() {
    return basePayload({
      message: 'Bag tag has not been accepted final attempt.',
      transactionType: TransactionTypes.Actionable,
      transactionSubTypes: [TransactionSubTypes.UnreadableBagTag],
    });
  },

  /** Timatic unsuccessful for individual passenger.*/
  timaticIndividual({ passenger, message }) {
    return basePayload(
      {
        message: 'Timatic response check required',
        transactionType: TransactionTypes.Actionable,
        transactionSubTypes: [TransactionSubTypes.Timatic],
        status: TransactionStatuses.Pending,
        data: {
          timaticData: {
            responses: [message],
          },
        },
      },
      null,
      {
        passengerID: passenger.passengerID,
        firstName: passenger.firstName,
        lastName: passenger.lastName,
      },
    );
  },

  biometricCheck(responses) {
    return basePayload({
      message: 'Biometric check required',
      transactionType: TransactionTypes.Actionable,
      transactionSubTypes: [TransactionSubTypes.Biometric],
      status: Statuses.Pending,
    });
  },

  /** Failed check in (EYSS-1108) */
  checkInFailed(passenger) {
    return basePayload(
      {
        message: `Check in failed for ${passenger.firstName} ${passenger.lastName}.`,
        transactionType: TransactionTypes.Alert,
        transactionSubTypes: [TransactionSubTypes.CheckInFailed],
      },
      null,
      passenger,
    );
  },

  /** Plusgrade pending. EYSS-2260. */
  plusgradePending() {
    return basePayload({
      message: 'Plusgrade upgrade is pending on bag drop.',
      transactionType: TransactionTypes.Actionable,
      transactionSubTypes: [TransactionSubTypes.CabinUpgradeRequested],
    });
  },

  seatChangePaymentWarningAccepted(message, _booking, _passenger) {
    if (!message) {
      message = `Passenger selected a chargable seat and Accepted to pay.`;  
    }

    return basePayload({
      message,
      transactionType: TransactionTypes.Alert,
      transactionSubTypes: [TransactionSubTypes.Unknown],
    }, _booking, _passenger);
  },

  seatChangePaymentWarningDeclined(message, _booking, _passenger) {
    if (!message) {
      message = `Passenger selected a chargable seat. But declined when asked if they would pay.`;  
    }

    return basePayload({
      message,
      transactionType: TransactionTypes.Alert,
      transactionSubTypes: [TransactionSubTypes.Unknown],
    }, _booking, _passenger);
  },

  searchBookingAPIForXCI: makeSimpleAlert(
    'In order to allow agent to override excess piece(s), an excess calculation id is required. The service failed to retrieve it',
  ),

  overrideExcessBagPieceFailed: makeSimpleAlert(
    'Updating Agent excess piece override to CM failed',
  ),
  
  /** Activate bag tag request failed. EYSS-2466. */
  activateBagTagFailed: makeSimpleAlert(
    'Failed activating bag tag. Transaction ended.',
  ),

  /** Unexpected failure following checkin request to Switchboard */
  appCheckInFailure: makeSimpleAlert(
    'Unknown failure checking in passengers. Review booking data.',
  ),

  /** Piece weight allowance has been exceeded. EYSS-2567. */
  exceededPieceWeightAllowance: makeSimpleAlert(
    'Passenger piece allowance exceeded.',
  ),

  /** Bag weight exceeded. EYSS-2554. */
  paymentInProgress: makeSimpleAlert('Payment is in progress.'),

  /** Declined weight upgrade payment. EYSS-995. */
  declinedWeightUpgradePayment: makeSimpleAlert(
    'Passenger aborted weight payment.',
  ),

  declineSeatChangePayment: makeSimpleAlert(
    'Passenger aborted seat payment.',
  ),

  /** Couldn't connect to CUSS platform. EYSS-294. */
  hardwareUnavailable: makeSimpleAlert('Hardware Unavailable.'),

  /** Issue bag tag request failed. EYSS-2465. */
  issueBagTagFailed: makeSimpleAlert(
    'Failed issuing bag tag. Transaction ended.',
  ),

  addBaggageInformationFailed: makeSimpleAlert(
    'Add Baggage Information Service Returned an error. Transaction ended.',
  ),

  updateBaggageInformationFailedUnpaidExcessExists: makeSimpleAlert(
    'Update baggage information has failed. Unpaid Excess returned by Amadeus which app did not detect. Transaction ended.',
  ),

  customerAcceptanceFailed: makeSimpleAlert(
    'The Customer Acceptance has failed. Transaction ended.',
  ),

  seatReallocationFailed: makeSimpleAlert(
    'Error while changing back the passenger seats. Transaction ended.',
  ),

  /** Timatic status CONDITIONAL or NOT_OK_TO_BOARD still exists after visa updates and overrides */
  timaticCheckReturnedConditionalOrNotOkToBoard(message)
  {
    if (!message) {
      message = `Timatic status of either Conditional or Not ok to board still exists after updating Visa details and/or Agent overrides.`;  
    }

    return basePayload({
      message,
      transactionType: TransactionTypes.Alert,
      transactionSubTypes: [TransactionSubTypes.Unknown],
    });
  },

  customerAcceptanceFailedWithReason(message)
  {
    if (!message) {
      message = `Customer Acceptance has failed.`;  
    }

    return basePayload({
      message,
      transactionType: TransactionTypes.Alert,
      transactionSubTypes: [TransactionSubTypes.Unknown],
    });
  },

  /** Get Bag Allowance service failed */
  bagAllowanceRetrievalFailed: makeSimpleAlert(
    'Baggage Allowance could not be retrieved. Transaction ended.',
  ),

  /** Accept baggage group error */
  bagAcceptanceFailed: makeSimpleAlert(
    'Baggage Acceptance service failed. Transaction ended.',
  ),
  
  /** General application failure. EYSS-1129. */
  generalFailure: makeSimpleAlert('General application failure.'),

  /** Transaction timed out. EYSS-624. */
  transactionEndedByTimeout: makeSimpleAlert('Transaction timed out'),

  /** Passenger ends from timeout. EYSS-624. */
  passengerEndedFromTimeout: makeSimpleAlert('Passenger ended from timeout'),

  /** Seat change request failed. EYSS-1053. */
  seatChangeFailed: makeSimpleAlert('Seat change failure'),

  /** Resolution settings incorrect. EYSS-2613. */
  resolutionIncorrect: makeSimpleAlert(
    'Resolution incorrect. Technical support required.',
  ),

  /** Seat change request failed. EYSS-1053. */
  retrievePaymentStatusFailed: makeSimpleAlert(
    'Failed to retrieve payment status',
  ),

  /** Fatal Conveyor error. */
  fatalConveyorError: makeSimpleAlert(
    'HARDWARE ERROR: Unable to proceed with baggage handling.',
  ),

    /** Timatic failures other than CONDITIONAL, NOT_OK_TO_BOARD. EYSS-1982 */
  rerunADCChecksFailed: makeSimpleAlert(
    'ADC Checks have failed. Passenger(s) must attend service desk.'
  ),

  /** Timatic failures other than CONDITIONAL, NOT_OK_TO_BOARD. EYSS-1982 */
  timaticChecksFailed: makeSimpleAlert(
    'Timatic checks have failed. Passenger(s) must attend service desk.'
  ),

  /** Timatic failures other than CONDITIONAL, NOT_OK_TO_BOARD. EYSS-1982 */
  timaticFailureOverrideUnavailable: makeSimpleAlert(
    'Timatic failures cannot be recovered by override. ' +
      'Passenger(s) must attend service desk.',
  ),

  /** Blocked multisector bookings as per feature flag.*/
  multiSectorBooking: makeSimpleAlert(
    'Multisector booking rejected. ' +
      'Multisector functionality has been disabled on the kiosk.',
  ),

  /** Checked-in passengers without passport numbers in their docS. */
  noValidDocSForCheckedInPassenger: makeSimpleAlert(
    'The booking contains passengers who are checked in, but whose ' +
      'DocS does not contain a valid passport number.',
  ),

  /** End transaction for missing SSR. EYSS-2503 */
  PCRTestFailed: makeSimpleAlert(
    'Passenger does not have PCR test recorded. Transaction ended.',
  ),

  passengerOffloaded: makeSimpleAlert(
    'Passenger offload caused by seat change. Requires manual remediation. Transaction ended.',
  ),

  /** CCNV SSR eligibility. EYSS-2343 */
  ccnvSSRFound: makeSimpleAlert('CCNV SSR found. Transaction ended.'),

  /** Payment cancelled/failed. EYSS-1624. */
  excessBaggagePaymentFailed(exceededBy, excessWeightCost, currency) {
    return basePayload({
      message:
        ` Total allowance exceeded by ${exceededBy}${booking.getBaggageUnit()}.` +
        ` Payment of ${excessWeightCost} ${currency} cancelled or failed.`,
      transactionType: TransactionTypes.Actionable,
      transactionSubTypes: [
        TransactionSubTypes.PaymentError,
        TransactionSubTypes.ExcessBaggagePaymentDeclined,
      ],
      data: {
        bagDropData: {
          exceededBy,
          excessWeightCost,
          currency,
        },
      },
    });
  },

    /** Payment success EMD fail. EYSS-3436. */
  excessBaggagePaymentSuccessEMDIssuanceFail() {
    return basePayload({
      message:
        `Payment was successful but EMD issuance failed. Override to retry EMD issuance or end the transaction.`,
      transactionType: TransactionTypes.Actionable,
      transactionSubTypes: [
        TransactionSubTypes.PaymentError,
        TransactionSubTypes.ExcessBaggagePaymentDeclined,
      ]
    });
  },

  /** User requests assistance. EYSS-1184. */
  assistanceRequired(screenName) {
    return basePayload({
      message: 'Assistance Required.',
      transactionType: TransactionTypes.Actionable,
      transactionSubTypes: ['AssistanceRequest'],
      data: {
        bagDropData: {
          screenName: screenName,
        },
      },
    });
  },

  /** Specific alerts for eligibility check failures. EYSS-1046. */
  /** Specific alerts for eligibility check failures. EYSS-1046. */
  eligibilityCheckFailure(eligibilityCheck) {
    // Maps to the eligibility errors in Switchboard
    const messageMap = {
      APP_STATUS_NOT_CLEAR: 'Eligibility Failure - APP Status not clear',
      BASE_STATION_NOT_FOUND: 'Base station not found',
      CHECKED_IN_PASSENGER_ON_STAND_BY: 'Eligibility Failure - Checked-in pax on standby',
      DISALLOWED_DESTINATION: 'Eligibility Failure - Disallowed destination',
      DISALLOWED_SSR: 'Eligibility Failure - Disallowed SSR',
      EXCESS_CHARGES_WAIVED: 'Eligibility Failure - Excess charges have been waived',
      GREATER_THAN_9PAX: 'Eligibility Failure - More than 9 pax in retrieved booking',
      INFANT_PASSPORT_NOT_ALLOWED: 'Eligibility Failure - Infant passport not allowed',
      INVALID_BOOKING_STATUS: 'Eligibility Failure - Invalid booking status',
      INVALID_MULTI_SEGMENT_CHECK_IN_STATUS: 'Eligibility Failure - Invalid multi segment check in status',
      PASSENGER_HAS_UNPAID_ANCILLARY: 'Eligibility Failure - Passenger has unpaid ancillary',
      PASSENGER_TOO_EARLY: 'Eligibility Failure - Passenger too early',
      PASSENGER_TOO_LATE: 'Eligibility Failure - Passenger too late',
      SEARCH_BOOKING_FAILED: 'Search booking failed',
      TRIP_NOT_FOUND: 'Trip not found',
      // Minor travelling unaccompanied (without adult)
      UNACCOMPANIED_MINOR_FOUND: 'A UMNR is attempting to retrieve booking',
      WRONG_PASSPORT_SCANNED: 'Passenger has scanned the wrong passport.',
      PIECE_ALLOWANCE_WITH_HAND_BAG_ONLY:
        'Piece market bookings with hand bag only (HBO) are not allowed',
    };

    if (!(eligibilityCheck in messageMap)) {
      return payloads.bookingNotFound();
    }

    return basePayload({
      message: messageMap[eligibilityCheck],
      transactionType: TransactionTypes.Alert,
      transactionSubTypes: [TransactionSubTypes.BookingNotFound],
    });
  },

  /** Unmatched scan for adult/infant pair. EYSS-782. */
  unmatchedScanForAdultInfantPair() {
    return basePayload({
      message: 'Adult / infant not scanned together.',
      transactionType: TransactionTypes.Actionable,
      transactionSubTypes: ['PassportScanFailed'],
    });
  },

  /** There is a hard error with a CUSS device. */
  deviceHardError(msg) {
    return makeSimpleAlert(msg)();
  },

  /** There has been no weight data received from scale device. */
  noWeightDataReceived: makeSimpleAlert(
    'HARDWARE ERROR: No weight data received from scale.',
  ),
};

export default payloads;
