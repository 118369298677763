<script>
  import { _ } from 'svelte-i18n';
  import { get } from 'svelte/store';
  import { onMount, onDestroy } from 'svelte';
  import { push } from 'svelte-spa-router';
  
  import { ApplicationStep, appReport } from '../../js/appReport';
  import {
    CustomErrorModal,
    areTimaticsPending,
    booking,
    checkInPassengersManager,
    passengerMrzManager,
    pnr,
    setErrorModal,
    timaticCheckManager,
    wizardPosition,
    selectedYesToSeatMapSelection
  } from '../../js/stores';
  import { ErrorModals } from '../../js/const/errorModals';
  import { END_TRANSACTION_REASON, WizardPosition } from '../../js/const';
  import endTransaction from '../../js/endTransaction';
  import flightdeck from '../../js/services/flightdeck';
  import flightdeckConsts from '../../js/services/flightdeck/const';
  import { handleGeneralFailure } from '../../js/global-error-handler.js';
  import logger from '../../js/logger';

  import Footer from '../components/Footer/index.svelte';
  import Header from '../components/Header/index.svelte';
  import Loading from '../components/Loading.svelte';

  wizardPosition.set(WizardPosition.PASSENGERS);

  let pendingOverrides = 0;
  let cancelTimaticOverride = null;

  $: logger.info(
    `There are ${pendingOverrides} timatics pending Agent override.`,
  );

  /** Advance to the next screen. */
  function advanceToNextScreen() {   
    // If all pax are checked in but some have ADC checks required
    if (checkInPassengersManager.hasCheckInPassengers()) { // check if any passengers need to be checked in
      push('/emergency-contact');
    } 
    // If no pax requiring check-in, but just some needing ADC checks, go through a different route
    else if (booking.hasPassengersRequiringADCCheck()) {
      push('/verifying-adc-checks');
    }    
    else {
      appReport.updateStepSuccess(ApplicationStep.PASSENGER_ADC_CHECKS);
      
      if(!booking.isAnyPassengerStaffandStandBy() && get(selectedYesToSeatMapSelection))
      {
          push('/seat-map');
      }
      else
      {
          push('/number-of-bags')
      }
    }
  }

  function safeCancelTimaticOverride() {
    if (cancelTimaticOverride !== null) {
      logger.debug('Cancel timatic override (of unsuccessful checks).');
      cancelTimaticOverride();
      cancelTimaticOverride = null;
    }
  }

  const unsubscribes = Array();

  onMount(() => {
    appReport.updateStepStart(ApplicationStep.PASSENGER_ADC_CHECKS);
    advanceToNextScreen();
  });
  
  onDestroy(() => {
    while (unsubscribes.length) {
      const unsubscribe = unsubscribes.pop();
      unsubscribe();
    }

    safeCancelTimaticOverride();
  });
</script>

<Header />

<Loading heading={$_('app.pleaseWait')} />

<Footer />
