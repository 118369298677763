<script>
  import { _ } from 'svelte-i18n';
  import { createEventDispatcher } from 'svelte';
  import { get } from 'svelte/store';
  import { onMount } from 'svelte';
  import { handleTransactionEnd } from '../../../js/handleTransactionEnd';
  import { ApplicationStep, appReport, FailedReason } from '../../../js/appReport';
  import {
    appletRunning,
    booking,
    infants,
    checkInPassengersManager,
    currentPassenger,
    documentId,
    featureFlags,
    headPassengerManager,
    pnr,
    printBoardingPass,
    purchaseManager,
    receipt,    
    rightToLeft,
    setErrorModal,
    timeoutBlocked,
    updatedEmail,
    amadeusBoardingPassUri,
    selectedYesToSeatMapSelection,
    didAnyPassengerSeatChange
  } from '../../../js/stores';
  import {    
    FeatureFlags,
    FlightDeckMessages,
    WeightUnit,
  } from '../../../js/const';
  import { ErrorModals } from '../../../js/const/errorModals';
  import {
    dateFormat,
    stripLeadingZeroes    
  } from '../../../js/utils';
  import flightdeck from '../../../js/services/flightdeck';
  import { getReceiptExecutor } from '../../../js/cuss-components';
  import logger from '../../../js/logger';
  import { onlyOnce } from '../../../js/utils';
  import {
    printBoardingPasses,
    reprintBoardingPasses,
  } from '../../../js/cuss-components';
  import switchboardClient from '../../../js/services/switchboard';
  import restfulAPIClient from '../../../js/services/restfulAPI';
  import { VoiceIntent } from '../../../js/services/voicerec/voicerec';

  import Button from '../../components/Button.svelte';
  import Message from '../../components/Icons/Message.svelte';
  import Printer from '../../components/Icons/Printer.svelte';

  import pencil from '../../../svg/pencil.svg';

  export let passenger = null;
  export let showChangeEmailAddress = null;
  export let boardingPassPrintDataAvailable = false;
  // this will be turned to false when we receive BP response , whether error or full data received
  export let awaitingBoardingPassResponse = true;

  const BAG_RECEIPT = 'BAG_RECEIPT';
  const dispatch = createEventDispatcher();
  const PURCHASE_RECEIPT = 'PURCHASE_RECEIPT';
  const isEmailEnabled = featureFlags.isEnabled(FeatureFlags.EMAIL);
  
  let receiptDocumentId = null;
  let emailDisabled = null;
  let printDisabled = false;
  let printBagReceipt = false;
  let printOrEmailClicked = false;  
  let boardingPassPrintRequired = true;

  $: emailAddress = $updatedEmail || headPassengerManager.getEmailAddress();

  /** Print Bag Receipts. */
  function printBagReceipts() {
    // print bag receipts

    let paxDetailsData = [];
    const receiptData = get(receipt);
    const segments = booking.getDisplayedSegments(
      false,
      null,
      currentPassenger.getShortCheckInDestinationCity()
    );

    logger.info(
      `printBagReceipts(). ` +
        `receipt object is: ${JSON.stringify(receiptData)}` +
        `receipt items: ${receiptData && receiptData.length}; ` +
        `segments: ${segments && segments.length}.`,
    );

    if (receiptData.length > 0) {
      appReport.updateStepStart(ApplicationStep.PRINT_BAGGAGE_RECEIPT);

      let receiptBag = null;
      for (let i = 0; i < receiptData.length; i++) {
        receiptBag = receiptData[i];
        paxDetailsData += `${i + 1}/${receiptData.length} ${
          receiptBag.weight
        }${WeightUnit},${receiptBag.checkedTime
          .toLocaleString()
          .replace(',', '')},${receiptBag.tagNumberPrefix}${receiptBag.tagNumber}&`;
      }

      if (!$appletRunning) {
        logger.info(
          'Aborting print receipt because CUSS applet is not running.',
        );
        appReport.updateStepFailed(
          ApplicationStep.PRINT_BAGGAGE_RECEIPT,
          FailedReason.NO_CUSS_APPLET
        );
        return;
      }

      const receiptExecutor = getReceiptExecutor(BAG_RECEIPT);
      receiptExecutor.bagReceiptData.passengerName = `${$currentPassenger.lastName}/${$currentPassenger.firstName}`;
      receiptExecutor.bagReceiptData.flightDate = `${dateFormat(
        $booking.departureDate,
      )}`;
      const paxDetailsSegment = segments.map((segment) => [
        `${segment.airlineCode}${stripLeadingZeroes(
          segment.flightNumber,
        )}, ${$pnr}, ${segment.departureCode}/${segment.arrivalCode}, [${paxDetailsData}]`,
      ]);

      timeoutBlocked.set(true);
      receiptExecutor.flightDetails = paxDetailsSegment;

      try {
        receiptExecutor.executeReceiptPrinting();
        timeoutBlocked.set(false);
        appReport.updateStepSuccess(ApplicationStep.PRINT_BAGGAGE_RECEIPT);

        if (purchaseManager.havePurchases()) {
          printPurchaseReceipt();
        }
      } catch (error) {
        logger.warn(FlightDeckMessages.ATB_PRINT_FAILURE, error);
        timeoutBlocked.set(false);
        flightdeck.atbPrintFailure(get(booking), passenger);
        appReport.updateStepFailed(
          ApplicationStep.PRINT_BAGGAGE_RECEIPT,
          FailedReason.PRINT_FAILED
        );
        handleTransactionEnd();
      }
    } else if (purchaseManager.havePurchases()) {
      logger.info(`No bag receipts to print. But we have purchases like seat change etc.`);
      printPurchaseReceipt();
    }
  }  

  /** Print Purchase Receipts. */
  function printPurchaseReceipt() {
    // print EMD purchase receipts
    
    const $pnr = get(pnr);
    const purchases = purchaseManager.getPurchases();

    if (!$appletRunning) {
      logger.info(
        'Aborting print purchase receipt because CUSS applet is not running.',
      );
      return;
    }

    const purchaseReceiptExecutor = getReceiptExecutor(PURCHASE_RECEIPT);

    purchaseReceiptExecutor.purchaseReceipt.paxName =
      $currentPassenger.lastName + '/' + $currentPassenger.firstName;
    purchaseReceiptExecutor.purchaseReceipt.pnr = $pnr;

    logger.info(`Printing purchase receipts.`);

    const purchaseDetailsList = purchases.map((purchase) => [
      purchase.description,
      purchase.price,
      purchase.currency,
      purchase.emdNumber,
    ]);

    if (purchases && purchases.length > 0) {
      purchases.forEach((p) => {
        if (p) {
          logger.info(`Purchase Description: ${p.description}, Price: ${p.price}, Currency: ${p.currency}, EMDNumber: ${p.emdNumber}`);  
        }
      });      
    }

    purchaseReceiptExecutor.purchaseDetails = purchaseDetailsList;

    try {
      logger.info('Printing purchase receipt.');
      purchaseReceiptExecutor.executeReceiptPrinting();
    } catch (error) {
      logger.warn(FlightDeckMessages.ATB_PRINT_FAILURE, error);
      flightdeck.atbPrintFailure(get(booking), passenger);
    }
  }

  /**
   * Advance to the Email action as per the applicationFlow.
   * Select screen based on applicationFlow mode.
   */
  function emailHandler() {   
    // If a pax has pressed the email button, the bag drop completed screen is a success.
    appReport.updateStepSuccess(ApplicationStep.BAG_DROP_COMPLETED_SCREEN);
 
    emailDisabled = true;
    printOrEmailClicked = true;
    dispatch('userInteractionFinished');

    //AMADEUS - Send an email of the boarding passes to passenger's email
    sendEmailProcessStart();
  }

  function sendEmailProcessStart() {
    restfulAPIClient.generateAmadeusToken()
    .then((response) => {
      if (response && response.access_token) {
        prepareEmailBodyAndSendEmail(response.access_token);

        receiptDocumentId = `${get(documentId)}` || null;

        if (receiptDocumentId) {
          emailDocumentReceipt(receiptDocumentId);
        }
        
        handleTransactionEnd();
      } else {
        setErrorModal(ErrorModals.RECEIPT_EMAIL_ERROR);
        flightdeck.atbPrintFailure(get(booking), passenger);
        handleTransactionEnd();
      }
    }).catch((error) => {       
        setErrorModal(ErrorModals.RECEIPT_EMAIL_ERROR);
        flightdeck.atbPrintFailure(get(booking), passenger);
        handleTransactionEnd();
    });
  }

  function emailDocumentReceipt(receiptDocumentId){
    
    switchboardClient
      .emailDocumentReceipts(emailAddress, receiptDocumentId)
      .then((response) => {
        logger.info('Response from emailing Document Receipt', response);        
      });
  }

  /** This send Email method runs for each passenger each segment*/
  function prepareEmailBodyAndSendEmail(accessToken) {
    appReport.updateStepStart(ApplicationStep.EMAIL_BOARDING_PASS);

    const passengersListForBP = getPassengersListForBP();
    if (passengersListForBP && passengersListForBP.length > 0) {
      passengersListForBP.forEach((passenger) => {
        const bpBody = {
          data: {
            emailTarget: {
              emailMessage: {
                fromAddress: {
                  address: 'noreply@etihad.com',
                },
                toAddresses: [{ address: passenger.email }],
                subject: 'Boarding Pass',
              },
              attachmentName: 'BoardingPass.pdf',
            },
            passengersAndSegmentsIds: [
              {
                dcsPassengerId: passenger.customerPrimeId,
                segmentDeliveryIds: passenger.productPrimeIds,
              },
            ],
          },
        };
        sendEmail(accessToken, JSON.stringify(bpBody));

        // TODO: We should probably check if sendEmail executes correctly before logging a success
        const eventDetails = {context: {passengerId: passenger.customerPrimeId}}
        appReport.updateStepSuccessWithEventDetails(ApplicationStep.EMAIL_BOARDING_PASS, eventDetails);
      });
      
    }
  }

  async function sendEmail(accessToken, boardingPassBody) {
    const baseUrl = get(amadeusBoardingPassUri); 

    const bpURL = await fetch(`${baseUrl}v3/departure-control/dcs-passengers/boarding-passes-dispatch`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': '*/*',
        'Accept-Encoding': 'gzip, deflate', 
        'Connection': 'keep-alive',
        'Authorization': `Bearer ${accessToken}`
      },
      body: boardingPassBody,
    });

    const boardingPassResponse = await bpURL.json();
    if (boardingPassResponse) {
      logger.info(`boarding pass email response is: ${JSON.stringify(boardingPassResponse)}`);
    }
  }

  /** Edit Email action to switchboard. */
  function editEmailHandler() {
    showChangeEmailAddress = true;
  }

  /**
   * Advance to the printer action as per the applicationFlow.
   * Select screen based on applicationFlow mode.
   */
  function printHandler() {
    // If a pax presses any button that navigates away from bag drop completed screen, bag drop completed is a success
    appReport.updateStepSuccess(ApplicationStep.BAG_DROP_COMPLETED_SCREEN);


    dispatch('userInteractionFinished');
    printDisabled = true;
    printOrEmailClicked = true;    
    printBagReceipts();

    const whenDone = () => {
      logger.info('Done printing, moving to completion screen.');
      handleTransactionEnd();
    };

    if (boardingPassPrintDataAvailable) {
      if (checkInPassengersManager.hasCheckInPassengers()) {
        printBoardingPasses().then(() => {
          reprintBoardingPasses(whenDone);
        });
      } else if (get(printBoardingPass) || 
                 (get(selectedYesToSeatMapSelection) && get(didAnyPassengerSeatChange))) {
        reprintBoardingPasses(whenDone);
      } else {
        whenDone();
      }
    } else {
      whenDone();
    }
  }

  function getPassengersListForBP() {
    let passengersForBP = [];
    let segments = [];

    const currentBooking = get(booking);
    let email = null;
    if (
      currentBooking &&
      currentBooking?.emailAddresses &&
      currentBooking?.emailAddresses?.length > 0
    ) {
      email = currentBooking?.emailAddresses[0];
    }

    get(booking).passengers.map((passenger) => {
      passengersForBP.push({
        email: get(updatedEmail) ? get(updatedEmail) : email,
        customerPrimeId: passenger.passengerID,
        productPrimeIds: passenger.segments.map((s) => {
          return s.passengerDID;
        }),
      });
    });

    const infantList = get(infants);
    if (infantList && infantList.length > 0) {
      infantList.map((infant) => {
        passengersForBP.push({
          email: email,
          customerPrimeId: infant.passengerID,
          productPrimeIds: infant.segments.map((s) => {
            return s.primeId;
          }),
        });
      });
    }

    return passengersForBP;
  }
 
  onMount(() => {
    emailDisabled = false;
    printDisabled = false;    
    
    logger.info('Receipt count is >>>>>>> ', get(receipt).length);
    if (get(receipt).length > 0) {
      printBagReceipt = true;
    }
    // if there are no passengers checked in by our app AND if already checked in before the transaction
    // passenger selected No Thanks to print boarding pass, we will not print boarding passes
    if (!checkInPassengersManager.hasCheckInPassengers() && !get(printBoardingPass) && (!get(selectedYesToSeatMapSelection) && !get(didAnyPassengerSeatChange))) {
      boardingPassPrintRequired = false;

      logger.info('No passengers checked in using ssbd. Guest also click No Thanks for boarding pass print.');
      if (get(receipt).length === 0) {
        logger.info('No bags checked in this transaction. calling handleTransactionEnd()');
        handleTransactionEnd();
      }
    } 

  });

</script>

<style>
  .email-print-options {
    text-align: center;

  }

  .email-print-text {
    text-align: center;    
  }
</style>

<div class="email-print-options mb-12">
  <h3 class="email-print-text">    
    {#if isEmailEnabled && get(receipt).length === 0 && !purchaseManager.havePurchases() && boardingPassPrintRequired}
      
      {$_('bagDropCompleted.emailOrPrint')}

    {:else if !isEmailEnabled && get(receipt).length === 0 && !purchaseManager.havePurchases() && boardingPassPrintRequired}
    
      {$_('bagDropCompleted.bpPrint')}

    {:else if boardingPassPrintRequired && get(receipt).length === 0 && purchaseManager.havePurchases()}
    
      {$_('bagDropCompleted.bpPrintWithPurchase')}  

    {:else if (!(boardingPassPrintRequired && boardingPassPrintDataAvailable) && get(receipt).length === 0 && purchaseManager.havePurchases())}

      {$_('bagDropCompleted.printPurchase')}

    {:else if (get(receipt).length > 0 && !purchaseManager.havePurchases() && !boardingPassPrintRequired)}
      
      {$_('bagDropCompleted.BagReceiptPrint')}

    {:else if (get(receipt).length > 0 && purchaseManager.havePurchases() && !boardingPassPrintRequired)}

      {$_('bagDropCompleted.BagAndPurchaseReceiptPrint')}

    {:else if (get(receipt).length > 0 && !purchaseManager.havePurchases() && (boardingPassPrintRequired && boardingPassPrintDataAvailable))}

      {$_('bagDropCompleted.bpAndBagReceiptPrint')}

    {:else if (get(receipt).length > 0 && purchaseManager.havePurchases() && boardingPassPrintRequired)}

      {$_('bagDropCompleted.bpAndBagAndPurchaseReceiptPrint')}

    {:else if (get(receipt).length > 0 && !purchaseManager.havePurchases() && !boardingPassPrintDataAvailable)}

      {$_('bagDropCompleted.BagReceiptPrint')}

    {:else}

      {$_('bagDropCompleted.printGeneral')}

    {/if}
  </h3>    
</div>

{#if isEmailEnabled && booking && get(receipt).length === 0 && !purchaseManager.havePurchases()}
  {#if emailAddress}
    <div class="flex justify-center w-full mb-12">
      <button
        class="flex items-center"
        role="button"
        on:click={editEmailHandler}
      >
        <h3 class="font-AltisMedium text-1.7 text-gold-100">
          {emailAddress}
        </h3>
        <div class="{$rightToLeft ? 'mr-3' : 'ml-3'} w-7">
          {@html pencil}
        </div>
      </button>
    </div>
  {/if}
{/if}

<div
  class="flex items-center {(isEmailEnabled && get(receipt).length === 0 && !purchaseManager.havePurchases())
    ? 'justify-between'
    : 'justify-center'} mb-24"
>
  {#if isEmailEnabled && get(receipt).length === 0 && !purchaseManager.havePurchases()}
    {#if emailAddress}
      <Button
        disabled={emailDisabled || !boardingPassPrintDataAvailable || printOrEmailClicked}
        icon={Message}
        iconSize="small"
        intent={VoiceIntent.EMAIL}
        on:click={onlyOnce(emailHandler)}
      >
        {#if emailDisabled}
          {$_('bagDropCompleted.emailBaggageReceiptSent')}
        {:else}
          {$_('bagDropCompleted.emailBaggageReceipt')}
        {/if}
      </Button>
    {:else}
      <Button        
        disabled={emailDisabled || !boardingPassPrintDataAvailable || printOrEmailClicked}  
        icon={Message}
        iconSize="small"
        intent={VoiceIntent.ADD}
        on:click={editEmailHandler}
      >
        {$_('bagDropCompleted.addEmailBaggageReceipt')}
      </Button>
    {/if}

    {#if isEmailEnabled && get(receipt).length === 0 && !purchaseManager.havePurchases()}  
      <div class="text-center">
        <h3 class="font-AltisMedium text-1.7 text-gold-100 uppercase">
          {$_('app.or')}
        </h3>
      </div>
    {/if}
  {/if}

  <Button
    disabled={awaitingBoardingPassResponse || printDisabled || (!boardingPassPrintDataAvailable && !printBagReceipt) || printOrEmailClicked}
    icon={Printer}
    iconSize="small"
    intent={VoiceIntent.PRINT}
    on:click={onlyOnce(printHandler)}
  >
    {#if printDisabled }
      {$_('bagDropCompleted.baggageReceiptPrinted')}
    {:else}
      {$_('bagDropCompleted.baggageReceipt')}
    {/if}
  </Button>
</div>