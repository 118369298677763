<script>
  import { _ } from 'svelte-i18n';
  import { get } from 'svelte/store';
  import { onMount } from 'svelte';
  import { push } from 'svelte-spa-router';
  import {
    rightToLeft,
  } from '../../js/stores';
  import Header from '../components/Header/index.svelte';
  import logger from '../../js/logger';
  import Loading from '../components/Loading.svelte';
  import Footer from '../components/Footer/index.svelte';

  let showUpdating = null;

  onMount(() => {    
    logger.info(`showing please wait page for 2 seconds.`)
    showUpdating = true;
    
    setTimeout(() => {
      showUpdating = false;
      logger.info(`Now redirecting back to visa verification.`)
      push('/visa-verification');
    }, 2000);
  });
</script>

<Header />

{#if showUpdating}
  <Loading heading={$_('visaVerification.pleaseWait')} />
{/if}

<Footer />
