<script>
  import { _ } from 'svelte-i18n';
  import { onMount } from 'svelte';

  import { addChar, backspace, clear, space } from '../InputField.svelte';
  import Button from './Button.svelte';
  import Row from './Row.svelte';
  
  export let showNumbers = true;
  export let hideSpecialCharacters = false;

  let isCompact = null;
  let rowA = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0'];
  let rowB = ['Q', 'W', 'E', 'R', 'T', 'Y', 'U', 'I', 'O', 'P'];
  let rowC = ['A', 'S', 'D', 'F', 'G', 'H', 'J', 'K', 'L', '_'];
  let rowD = ['@', 'Z', 'X', 'C', 'V', 'B', 'N', 'M', '.', '/'];

  function handlePress(event) {
    const { key } = event.detail;
    addChar(key);
  }

  onMount(() => {
    if (hideSpecialCharacters) {
      const compactItemsToRemove = new Set(['_', '@', '.']);
      rowC = rowC.filter((item) => !compactItemsToRemove.has(item));
      rowD = rowD.filter((item) => !compactItemsToRemove.has(item));
    }
  });
</script>

<div dir="ltr">
  {#if showNumbers}
    <Row on:pressed={handlePress} keys={rowA} />
  {/if}
  <Row on:pressed={handlePress} keys={rowB} />
  <Row on:pressed={handlePress} keys={rowC} />
  <Row on:pressed={handlePress} keys={rowD} />
  <Row>
    <Button class="w-1/3" on:click={clear}>
      {$_('app.clear')}
    </Button>

    <Button class="w-1/3 {isCompact ? 'invisible' : ''}" on:click={space}>
      {$_('app.space')}
    </Button>

    <Button class="w-1/3" on:click={backspace}>
      {$_('app.backspace')}
    </Button>
  </Row>
</div>
