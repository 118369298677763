import { _, locale } from 'svelte-i18n';
import { get } from 'svelte/store';

import {
  booking,
  checkoutFormURL,
  checkoutFormPayload,
  checkoutCurrentStatus,
  documentId,
  priceUnpaidItemsData,
  payetihadUri,
  purchaseManager,
  setErrorModal,
  transactionId,
  setPaymentDeclined,
  currentBag,
  seatPaymentProcessInProgress
} from '../../js/stores';
import { receipt } from '../../js/stores/receipt';
import { currencyFormat, safeObjectAttribute } from '../../js/utils';
import { ErrorModals } from '../const/errorModals';
import flightdeck from '../services/flightdeck';
import logger from '../logger';
import switchboardClient from '../services/switchboard';
import restfulAPIClient from '../../js/services/restfulAPI';
import {
  PAYMENT_STATUS_OK,
  PAYMENT_STATUS_NOT_EXIST
} from '../../js/const';
import { appReport, ApplicationStep } from '../../js/appReport'

const pollTimer = 1000; // In milliseconds
const translate = get(_);

let timeout = null;
let checkoutToken = null;


/**
 * @TODO: update comment
 * Encapsulates the Switchboard Seat object.
 */
export class PaymentController {
  /**
   * Sets the switchboard payment strings.
   * @param {string} currencyCode - Swtichboard currencyCode string.
   * @param {Function} handleAccepted - Swtichboard handleAccepted function.
   * @param {Function} handleDeclined - Swtichboard handleDeclined function.
   * @param {object} paymentItems - Payment Items List
   */
  constructor(extraAmount, currencyCode, handleAccepted, handleDeclined) {
    this.currencyCode = currencyCode;
    this.handleAccepted = handleAccepted;
    this.handleDeclined = handleDeclined;
    this.extraAmount = extraAmount;
    this.GUID = null;
    this.items = [];
    this.isPaymentSuccessful = false // OK status received
    this.paymentApprovalCode = null // Payment approval code from checkout form after a payment is successfully made with OK status
    this.emailAttemptCount = 0 // How many times we have tried emailing EY about failed EMD issuance
  }

  /**
   * Returns the total cost for the payment.
   *
   * @returns {string}
   */
  calculatePaymentTotal() {
    let purchaseTotal = 0;

    console.log(`inside calculatePaymentTotal and this.items is: ${JSON.stringify(this.items)}`);

    this.items.map((item) => {
      purchaseTotal += Number(item.totalPrice);
    });

    if (purchaseTotal > 0) {
      return translate('payment.purchaseTotal', {
        values: {
          amount: currencyFormat(get(locale), this.currencyCode, purchaseTotal),
        },
      });
    } else {
      return null; 
    }
  }

  /** Create a payment transaction record in Switchboard. */
  createPaymentTransaction() {
    const { bookingReference } = get(booking);
    logger.info(`Request createPaymentTransaction.`);

    return switchboardClient
      .createPaymentTransaction(
        this.currencyCode,
        bookingReference,
        this.items,
      )
      .then((response) => {
        logger.info(`Received createPaymentTransaction response.`);

        this.GUID = response.data.createPaymentTransaction.guid;
        if (!this.GUID) {
          throw new Exception(
            'Missing guid on createPaymentTransaction response.',
          );
        }

        this.getPaymentTransaction();
        return this.createPaymentTransactionURL();
      })
      .catch((error) => {
        logger.warn(`Failed createPaymentTransaction: ${error}`);
        setErrorModal(ErrorModals.ASSISTANCE_REQUIRED);
      });
  }

  prepareCheckoutForm() {
    try {
      return restfulAPIClient.generateAmadeusToken()
      .then((response) => {                
        if (response && response?.access_token) {
          checkoutToken = `${response?.access_token}`;
          // DC: just get the payload information once to create the checkout form
          var payload = get(checkoutFormPayload)
          checkoutCurrentStatus.set(null); // after getting a token, we will reset the checkout for status for generating new payment checkout url
          restfulAPIClient.checkoutForm(payload, response.access_token)
            .then((checkourFormResponse) => {
              if(checkourFormResponse && checkourFormResponse?.data && checkourFormResponse?.data?.id) {
                checkoutFormURL.set(
                  safeObjectAttribute(checkourFormResponse, 'data.targetUrl'),
                );
                logger.info('targetUrl ', checkourFormResponse?.data?.targetUrl);
                this.getCheckoutFormStatus(checkourFormResponse?.data?.id, checkoutToken);
              }
            })
            .catch((e) => {
              logger.warn(`Failed prepareCheckoutForm. Response is: ${response}`);
              setErrorModal(ErrorModals.ASSISTANCE_REQUIRED);
            });
        } else {
          logger.warn(`Payment checkout token could not be retrieved. Response is: ${response}`);
          setErrorModal(ErrorModals.ASSISTANCE_REQUIRED);
        }
      }).catch((e)  => {
        logger.warn('Exception occurred in restfulAPIClient.generateAmadeusToken()...', e);
      });
    } catch(e) {
      logger.warn('Exception occurred in prepareCheckoutForm()...', e);
    }
  }

  /**
   * Returns the payment transaction url.
   *
   * @returns {string}
   */
  useCheckoutFormTargetURL() {
    const targetUrl = `${get(checkoutFormURL)}`;
    return targetUrl;
  }

  /**
   * Returns the payment transaction url.
   *
   * @returns {string}
   */
  createPaymentTransactionURL() {
    return `${get(payetihadUri)}?id=${this.GUID}&transaction_id=${get(
      transactionId,
    )}`;
  }
  
  /**
   * Returns the heading of the seat payment.
   *
   * @returns {string}
   */
  getPaymentItemHeading() {
    throw new Error('Needs to be implemented on derived class.');
  }

  /** Poll the payment transaction in Switchboard, for a change in status. */
  getPaymentTransaction() {
    timeout = setTimeout(() => {
      logger.info(`Request paymentTransaction.`);

      switchboardClient
        .paymentTransaction(this.GUID)
        .then((response) => {
          const { paymentTransaction } = response.data;
          logger.info(
            `Resolved paymentTransaction (ID: ${this.GUID}), ` +
              `status: ${paymentTransaction && paymentTransaction.currentStatus})`,
          );

          if (paymentTransaction.currentStatus === 'APPROVED') {
            clearTimeout(timeout);

            const bookingReference = paymentTransaction.bookingReference;
            const currency = paymentTransaction.currency;

            paymentTransaction.paymentTransactionItem.map((paymentItem) => [
              purchaseManager.addPurchase(
                paymentItem.totalPrice,
                paymentItem.description,
                bookingReference,
                paymentItem.eMDNumber,
                currency,
              ),
            ]);
            this.handleAccepted();
          } else if (paymentTransaction.currentStatus === 'DECLINED') {
            clearTimeout(timeout);
            this.handleDeclined();
          } else if (paymentTransaction.currentStatus === 'ERROR') {
            throw new Error(
              'paymentTransaction error. ' +
                'EMD issuance failed, customer may have already been charged. ' +
                'Error message: ' +
                `${paymentTransaction.errorMessage}.`,
            );
          } else {
            this.getPaymentTransaction();
          }
        })
        .catch((error) => {
          clearTimeout(timeout);
          logger.warn(`Failed paymentTransaction: ${error.message}`);
          flightdeck.retrievePaymentStatusFailed();
          setErrorModal(ErrorModals.PAYMENT_TRANSACTION_ERROR);
        });
    }, pollTimer);
  }

  /** Poll the payment status from checkout form status. */
  getCheckoutFormStatus(pppId, apiTokenValue) {    
    if(pppId && apiTokenValue){
      timeout = setTimeout(() => {          
          restfulAPIClient.checkoutStatus(pppId, apiTokenValue)
          .then((response) => {            
            if (response) {
              if (response?.status) {
                // Only log the status if it has changed
                if(response?.status != get(checkoutCurrentStatus))
                {
                  logger.info(`Checkout Status: ${response?.status}`);
                }
              }
              checkoutCurrentStatus.set(
                safeObjectAttribute(response, 'status'),
              );                            

              if (response?.status && response?.status === PAYMENT_STATUS_OK) {
                logger.info(`The payment is done successfully`);

                // EG: APS1OK
                this.paymentApprovalCode = response?.paymentRecords?.authorization?.approvalCode
                this.isPaymentSuccessful = true

                appReport.updateAncillaryAmountPaid(this.extraAmount, this.currencyCode)  
                checkoutFormPayload.reset();
                clearTimeout(timeout);
                this.executeIssuePaymentDocument();
              } else if (response?.status && response?.status === PAYMENT_STATUS_NOT_EXIST){
                logger.info(`The PPID is expired or does not exist`);
                checkoutFormPayload.reset();
                clearTimeout(timeout);
                this.handleDeclined();
              } else{
                this.getCheckoutFormStatus(pppId, apiTokenValue);
              } 
            }
          })
          .catch((error) => {
            clearTimeout(timeout);

            appReport.updateStepFailed(ApplicationStep.EXCESS_BAGGAGE_PAYMENT)
            
            logger.warn(`Failed paymentTransaction: ${error.message}`);
            setErrorModal(ErrorModals.PAYMENT_TRANSACTION_ERROR);
          });
      }, pollTimer);
    }
  }

  executeIssuePaymentDocument(){
    const { bookingReference } = get(booking);
    
    let totalAmount = 0;

    logger.info(`Get price unpaid items are: ${priceUnpaidItemsDataResponse}`);

    const priceUnpaidItemsDataResponse = get(priceUnpaidItemsData);
    const unpaidItems = priceUnpaidItemsDataResponse?.data?.priceUnpaidItems?.unpaidItems;

    if (unpaidItems && unpaidItems.length > 0) {
      unpaidItems.forEach(u => {
        totalAmount = totalAmount + Number(u.price);
      });
    }

    switchboardClient
      .issuePaymentDocument(get(priceUnpaidItemsData)) 
      .then((response) => {
        logger.info(`Received issuePaymentDocument response.`, JSON.stringify(response));
        if (response && 
            response.data && 
            response.data.issuePaymentDocument &&          
            response.data.issuePaymentDocument.statusCode === 'Successful') {

          logger.info(`issue payment document response is: ${response.data.issuePaymentDocument}`);

          let emdIssuanceModule= 'Excess Baggage Allowance';
          logger.info(`seatPaymentProcessInProgress was: ${get(seatPaymentProcessInProgress)}. changing it to false`);
       
          let passengerId;
          let totalAmount = 0;
          let currency; 
          
          response.data.issuePaymentDocument.issuePaymentDocuments.forEach(i => {
            logger.info(`inside executeIssuePaymentDocument(), adding amount: ${i.amount}, EMD id: ${i.documentId} to purchaseManager.addPurchase.`);

            let pax = get(booking).passengers.filter(p => p.passengerID === i.id)[0];
            if (get(seatPaymentProcessInProgress)) {
              emdIssuanceModule = `Seat Change - ${pax.firstName} ${pax.lastName}`;
              passengerId = i.id;
              totalAmount = totalAmount + i.amount;
              currency = i.currencyCode;
            }

            logger.info(`emd issuance module is: ${emdIssuanceModule}`);
  
            purchaseManager.addPurchase(
              i.amount,
              emdIssuanceModule,
              bookingReference,
              i.documentId,
              i.currencyCode,
            );
          });
          
          seatPaymentProcessInProgress.set(false);    

          documentId.set(safeObjectAttribute(response, 'data.issuePaymentDocument.documentId'));

          appReport.updateStepSuccess(ApplicationStep.EXCESS_BAGGAGE_PAYMENT)
          this.handleAccepted();
        } else {
          logger.warn(`Amadeus issuePaymentDocument Service is failed.`);
          /**
           * Only available actions in FD are end and override
           * End -> handleFlightDeckPaymentFailureCancelled -> ends the transaction
           * Override -> handleFlightDeckPaymentFailureOverride -> handlePayment -> does the whole payment stuff again
           * So basically all the fix needs to entail is to override
           */

          this.emailAttemptCount += 1

          var emdIssuanceFailureEmailDetails = {
            bookingReference,
            approvalCode: this.paymentApprovalCode, 
            totalPrice: totalAmount.toString(), 
            currency: this.currencyCode,
            emailAttemptCount: this.emailAttemptCount
          }

          // Regardless of the state of the email, we go into retry flow. Log the outcome however.
          switchboardClient.emailEMDIssuanceFailed(emdIssuanceFailureEmailDetails)
          .then((response) => {
            if(response.isSuccessful)
            {
              logger.log("Email sent in payment success but emd issuance failed state.")
              this.handleDeclined();
            }
            else
            {
              logger.warn(`Error from SB in emailing EMD issuance failed to EY.`);
              this.handleDeclined();
            }
          })
          .catch((error) => {
            logger.warn(`Error connecting to SB to email EMD issuance failed to EY. Aborting transaction. ${error?.message}`);
            this.handleDeclined();
          })
        }
      })
      .catch((error) => {
        logger.warn(`Error connecting to Amadeus issuePaymentDocument Service: ${error}`);
        setErrorModal(ErrorModals.ASSISTANCE_REQUIRED);
      });
  }

  cancelPolling() {
    clearTimeout(timeout);
  }
}
