import Home from '../svelte/routes/Home.svelte';
import SearchingForYourBooking from '../svelte/routes/SearchingForYourBooking.svelte';
import PNRSearchForBooking from '../svelte/routes/PNRSearchForBooking.svelte';
import WelcomeSingle from '../svelte/routes/WelcomeSingle.svelte';
import ScanPassport from '../svelte/routes/ScanPassport.svelte';
import PassengersInBaggagePool from '../svelte/routes/PassengersInBaggagePool.svelte';
import PassportToCamera from '../svelte/routes/PassportToCamera.svelte';
import VerifyingPassport from '../svelte/routes/VerifyingPassport.svelte';
import SeatMap from '../svelte/routes/SeatMap.svelte';
import EmergencyContact from '../svelte/routes/EmergencyContact.svelte';
import VerifyingADCChecks from '../svelte/routes/VerifyingADCChecks.svelte';
import TripExtras from '../svelte/routes/TripExtras.svelte';
import CheckingInPassengers from '../svelte/routes/CheckingInPassengers.svelte';
import NumberOfBags from '../svelte/routes/NumberOfBags.svelte';
import DangerousGoods from '../svelte/routes/DangerousGoods.svelte';
import PrintingBagTag from '../svelte/routes/PrintingBagTag.svelte';
import PlacePassengerBag from '../svelte/routes/PlacePassengerBag.svelte';
import DispatchingBag from '../svelte/routes/DispatchingBag.svelte';
import BagDropCompleted from '../svelte/routes/BagDropCompleted.svelte';
import HaveANiceFlight from '../svelte/routes/HaveANiceFlight.svelte';
import PorterPlacePassengerBag from '../svelte/routes/PorterPlacePassengerBag.svelte';
import PorterPlacePassengerBagHybrid from '../svelte/routes/PorterPlacePassengerBagHybrid.svelte';
import PorterPrintingBagTag from '../svelte/routes/PorterPrintingBagTag.svelte';
import PorterPrintingBagTagHybrid from '../svelte/routes/PorterPrintingBagTagHybrid.svelte';
import PorterCheckingYourBag from '../svelte/routes/PorterCheckingYourBag.svelte';
import PorterCheckingYourBagHybrid from '../svelte/routes/PorterCheckingYourBagHybrid.svelte';
import RetrievingBagTags from '../svelte/routes/RetrievingBagTags.svelte';
import ErrorTrigger from '../svelte/routes/ErrorTrigger.svelte';
import Styleguide from '../svelte/routes/Styleguide.svelte';
import TestPage from '../svelte/routes/TestPage.svelte';
import Unavailable from '../svelte/routes/Unavailable.svelte';
import VisaVerification from '../svelte/routes/VisaVerification.svelte';
import PleaseWait from '../svelte/routes/PleaseWait.svelte';

const routes = new Map();
routes.set('/', Home);
routes.set('/searching/:hasScannedPassport?', SearchingForYourBooking);
routes.set('/pnr-searching', PNRSearchForBooking);
routes.set('/welcome-single', WelcomeSingle);
routes.set('/scan-passport', ScanPassport);

routes.set('/passengers-in-baggage-pool', PassengersInBaggagePool);

routes.set('/passport-to-camera', PassportToCamera);
routes.set('/passport-verification', VerifyingPassport);
routes.set('/emergency-contact', EmergencyContact);
routes.set('/verifying-adc-checks', VerifyingADCChecks);
routes.set('/trip-extras', TripExtras);
routes.set('/trip-extras/:returnPage?', TripExtras);
routes.set('/checking-in-passengers', CheckingInPassengers);
routes.set('/seat-map', SeatMap);
routes.set('/number-of-bags', NumberOfBags);
routes.set('/dangerous-goods', DangerousGoods);
routes.set('/printing-bag-tag', PrintingBagTag);
routes.set('/bag-drop-completed/:sentEmail?', BagDropCompleted);
routes.set('/retrieving-bag-tags', RetrievingBagTags);
routes.set('/place-passenger-bag', PlacePassengerBag);
routes.set('/dispatching-bag', DispatchingBag);

routes.set('/porter-place-passenger-bag', PorterPlacePassengerBag);
routes.set('/porter-place-passenger-bag-hybrid', PorterPlacePassengerBagHybrid);

routes.set('/porter-printing-bag-tag', PorterPrintingBagTag);
routes.set('/porter-printing-bag-tag-hybrid', PorterPrintingBagTagHybrid);

routes.set('/porter-checking-your-bag', PorterCheckingYourBag);
routes.set('/porter-checking-your-bag-hybrid', PorterCheckingYourBagHybrid);

routes.set('/error-trigger', ErrorTrigger);
routes.set('/styleguide', Styleguide);
routes.set('/test-page', TestPage);
routes.set('/unavailable', Unavailable);
routes.set('/have-a-nice-flight', HaveANiceFlight);
routes.set('/visa-verification', VisaVerification);
routes.set('/please-wait', PleaseWait);

export default routes;
