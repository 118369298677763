<script context="module">
  export const ButtonKinds = Object.freeze({
    PRIMARY: 'primary',
    SECONDARY: 'secondary',
    TERTIARY: 'tertiary',
  });
</script>

<script>
  import { hotkey } from '../../js/actions';

  export let hidden = false;
  export let disabled = false;
  export let intent = '';
  export let isArabic = false;
  export let icon = null;
  export let iconSize = '';
  export let isFull = false;
  export let kind = '';
  export let ref = null;
  export let size = '';

  $: buttonProps = {
    role: 'button',
    disabled,
    ...$$restProps,
    class: [
      'btn',
      hidden && 'hidden',
      icon && 'btn--icon',
      isArabic && 'btn--arabic',
      isFull && 'btn--full',
      kind === ButtonKinds.SECONDARY && 'btn--secondary',
      kind === ButtonKinds.TERTIARY && 'btn--tertiary',
      size === 'micro' && 'btn--micro',
      size === 'tiny' && 'btn--tiny',
      size === 'small' && 'btn--small',
      size === 'large' && 'btn--large',
      size === 'huge' && 'btn--huge',
      $$restProps.class,
    ]
      .filter(Boolean)
      .join(' '),
  };

  /**
   * Button component.
   *
   * For adding additional logic refer to
   * https://github.com/IBM/carbon-components-svelte/blob/master/src/Button/Button.svelte
   *
   * @example <caption>Example button.</caption>
   *  <Button
   *    class="mb-2"
   *    intent={VoiceIntent.NEXT}
   *    on:click={myCoolFunction}>
   *    {$_('app.next')}
   *  </Button>
   *
   * @example <caption>Example small secondary full arabic button.</caption>
   *  <Button
   *    class="mb-2"
   *    isArabic
   *    isFull
   *    intent={VoiceIntent.SKIP}
   *    kind={ButtonKinds.SECONDARY}
   *    size="small"
   *    on:click={myCoolFunction}>
   *    {$_('app.skip')}
   *  </Button>
   *
   * @example <caption>Example icon button.</caption>
   *  <Button
   *    class="mb-2"
   *    icon={ChevronRight}
   *    iconSize="small"
   *    intent={VoiceIntent.SKIP}
   *    kind={ButtonKinds.SECONDARY}
   *    on:click={myCoolFunction}
   *  />
   *
   * @type {String} class.
   * @type {Boolean} disabled.
   * @type {Boolean} isArabic.
   * @type {Boolean} isFull. - full width.
   * @type {String} intent.
   * @type {String} kind. - Use ButtonKinds.
   * @type {String} size. - small, large button etc.
   */
</script>

<style>
  .btn {
    @apply bg-gold-100
      border-3
      border-gold-100
      font-AltisMedium
      h-19
      px-6
      rounded-2
      text-3xl
      text-white
      w-68;
    line-height: 1;
  }

  .btn:active:not([disabled]) {
    @apply bg-gold-200 border-gold-200;
    box-shadow: 0px 7px 8px -4px rgb(189 139 19 / 20%),
      0px 12px 17px 2px rgb(189 139 19 / 14%),
      0px 5px 22px 4px rgb(189 139 19 / 12%);
  }

  .btn--icon {
    @apply flex items-center justify-center;
  }

  .btn--icon .icon {
    @apply -mt-1 w-20;
  }

  .btn--icon .icon--small {
    @apply mt-0 pl-0 pr-6;
  }

  :global([dir='rtl']) .btn--icon .icon--small {
    @apply pl-6 pr-0;
  }

  .btn--micro {
    @apply h-5 px-1 rounded-sm text-0.5 w-auto;
  }

  .btn--tiny {
    @apply h-8 px-3 text-base w-auto;
  }

  .btn--small {
    @apply h-13 px-4 text-xl w-auto;
  }

  .btn--large {
    @apply h-28 text-2.16;
  }

  .btn--huge {
    @apply h-40 text-2.16;
  }

  .btn--full {
    @apply w-full;
  }

  .btn--arabic {
    @apply font-Arabic;
  }

  .btn--secondary {
    @apply bg-transparent text-gold-100;
  }

  .btn--secondary:active {
    @apply bg-gold-200 border-gold-200 text-white;
  }

  .btn--tertiary {
    @apply bg-charcoal-100 border-charcoal-100 text-white;
  }

  .btn--tertiary:active {
    @apply bg-backgroundGrey border-backgroundGrey;
  }

  button:disabled {
    @apply bg-charcoal-10 border-charcoal-10 text-charcoal-100;
  }

  button.hidden {
    visibility: hidden;
  }


</style>

<button
  bind:this={ref}
  {...buttonProps}
  on:click
  on:mouseover
  on:mouseenter
  on:mouseleave
  use:hotkey
  {intent}
>
  {#if icon}
    <div class="icon" class:icon--small={iconSize === 'small'}>
      <svelte:component this={icon} />
    </div>
  {/if}
  <slot />
</button>
