<script>
  import { onMount } from 'svelte';
  import { location, push } from 'svelte-spa-router';
  import { get } from 'svelte/store';
  import { onDestroy } from 'svelte';
  import ModalBare from './ModalBare.svelte';
  import Json from './Json.svelte';
  import DebugBag from './DebugBag.svelte';
  import {
    ApplicationFlow,
    FeatureFlags,
    TransactionInitiator,
  } from '../../js/const';
  import { SBDStates } from '../../js/const/cussSBD';
  import { sbdState, transition_READY_TO_INJECT__TAG_ACTIVATED } from '../../js/stores/sbdState';
  import { ErrorModals } from '../../js/const/errorModals';
  import {
    appletRunning,
    applicationFlow,
    awaitingTimeoutRecovery,
    bagInjected,
    boardingPass,
    booking,
    currentBag,
    currentBagIndex,
    enableEligibilityCheck,
    enableHotkeys,
    errorModal,
    featureFlags,
    flightdeckApiKey,
    flightdeckUri,
    ignoreMissingComponents,
    loggerUri,
    mrz,
    passengerBagCounts,
    payetihadUri,
    pnr,
    setErrorModal,
    setIsLifted,
    switchboardUri,
    switchboardApiKey,
    tagNumbers,
    userInitiationMethod,
    getKioskName,
  } from '../../js/stores';
  import DebugNav from './DebugNav.svelte';
  import DebugPNRInput from './DebugPNRInput.svelte';
  import DebugBarcodeInput from './DebugBarcodeInput.svelte';
  import DebugMRZInput from './DebugMRZInput.svelte';
  import { onElementDestroy } from '../../js/actions';
  import cussBridge from '../../js/cuss-bridge';
  import { prettifyAllCaps, routeName } from '../../js/utils';
  import routes from '../../js/routes';

  const tabs = ['Status', 'Config', 'Inputs', 'Responses', 'Screens'];
  let activeTab = 'Inputs';

  export const InputOptions = Object.freeze({
    PNR: 1,
    BOARDING_PASS: 2,
    PASSPORT_MRZ: 3,
  });

  let inputSelect = InputOptions.PNR;
  let selectedFlow = $applicationFlow;

  /** Reload the application when the flow config changes. */
  function restartAppForFlow(node) {
    return {
      destroy() {
        if (selectedFlow === $applicationFlow) {
          return;
        }

        const newUrl =
          window.location.origin +
          qs.stringify(
            {
              flow: Object.keys(ApplicationFlow).find(
                (key) => ApplicationFlow[key] === selectedFlow
              ),
            },
            true
          );
        window.location.href = newUrl;
      },
    };
  }

  /** routesArray used for Chrome 40-44 compatibility. */
  const routesArray = [];
  routes.forEach((_, route) => {
    routesArray.push(route);
  });
</script>

<ModalBare>
  <div class="z-50 w-13/15 bg-white max-h-screen overflow-y-auto" slot="modal">
    <div style="margin: 20px; text-align: center; font-size: 20px; font-weight: bold; width: 100%">version 2.8.5</div>
    <DebugNav bind:activeTab {tabs} />
    {#if activeTab === 'Status'}
      <table class="mt-8 w-13/15 ml-1/15">
        <thead class="border-b-2 border-gray-300">
          <tr>
            <th scope="col" class="px-4 text-left">Item</th>
            <th scope="col">Status</th>
          </tr>
        </thead>
        <tbody class="text-sm">
          <tr class="h-12">
            <th class="px-4 text-left" scope="row">CUSS Applet Running</th>
            <td class="text-center">
              <span
                class="text-white font-bold py-1 px-2 rounded-full text-xs"
                class:bg-palmGreen={$appletRunning}
                class:bg-saffron={!$appletRunning}
              >
                {#if $appletRunning}Yes : {getKioskName()}{:else}No{/if}
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    {:else if activeTab === 'Config'}
      <div class="flex items-center my-8 w-14/15 mx-auto">
        <div class="w-1/5">
          <label
            class="block text-base text-gray-500 font-bold md:text-right mb-1
            md:mb-0 pr-4"
            for="switchboard-uri"
          >
            Switchboard URI
          </label>
        </div>
        <div class="w-12/15">
          <input
            class="bg-gray-200 appearance-none border-2 border-gray-200 rounded
            w-full py-2 px-4 text-gray-500 leading-tight focus:outline-none
            focus:bg-white focus:border-blue-500"
            id="switchboard-uri"
            type="text"
            value={$switchboardUri}
            use:onElementDestroy={(val) => switchboardUri.set(val)}
          />
        </div>
      </div>

      <div class="flex items-center my-8 w-14/15 mx-auto">
        <div class="w-1/5">
          <label
            class="block text-base text-gray-500 font-bold md:text-right mb-1
            md:mb-0 pr-4"
            for="switchboard-uri"
          >
            Switchboard API key
          </label>
        </div>
        <div class="w-12/15">
          <input
            class="bg-gray-200 appearance-none border-2 border-gray-200 rounded
            w-full py-2 px-4 text-gray-500 leading-tight focus:outline-none
            focus:bg-white focus:border-blue-500"
            id="switchboard-apikey"
            type="text"
            value={$switchboardApiKey}
            use:onElementDestroy={(val) => switchboardApiKey.set(val)}
          />
        </div>
      </div>

      <div class="flex items-center my-8 w-14/15 mx-auto">
        <div class="w-1/5">
          <label
            class="block text-base text-gray-500 font-bold md:text-right mb-1
            md:mb-0 pr-4"
            for="flightdeck-uri"
          >
            Flight Deck URI
          </label>
        </div>
        <div class="w-12/15">
          <input
            class="bg-gray-200 appearance-none border-2 border-gray-200 rounded
            w-full py-2 px-4 text-gray-500 leading-tight focus:outline-none
            focus:bg-white focus:border-blue-500"
            id="flightdeck-uri"
            type="text"
            value={$flightdeckUri}
            use:onElementDestroy={(val) => flightdeckUri.set(val)}
          />
        </div>
      </div>

      <div class="flex items-center my-8 w-14/15 mx-auto">
        <div class="w-1/5">
          <label
            class="block text-base text-gray-500 font-bold md:text-right mb-1
            md:mb-0 pr-4"
            for="flightdeck-uri"
          >
            Flight Deck API key
          </label>
        </div>
        <div class="w-12/15">
          <input
            class="bg-gray-200 appearance-none border-2 border-gray-200 rounded
            w-full py-2 px-4 text-gray-500 leading-tight focus:outline-none
            focus:bg-white focus:border-blue-500"
            id="flightdeck-apikey"
            type="text"
            value={$flightdeckApiKey}
            use:onElementDestroy={(val) => flightdeckApiKey.set(val)}
          />
        </div>
      </div>

      <div class="flex items-center my-8 w-14/15 mx-auto">
        <div class="w-1/5">
          <label
            class="block text-base text-gray-500 font-bold md:text-right mb-1
            md:mb-0 pr-4"
            for="logger-uri"
          >
            Logger URI
          </label>
        </div>
        <div class="w-12/15">
          <input
            class="bg-gray-200 appearance-none border-2 border-gray-200 rounded
            w-full py-2 px-4 text-gray-500 leading-tight focus:outline-none
            focus:bg-white focus:border-blue-500"
            id="logger-uri"
            type="text"
            value={$loggerUri}
            use:onElementDestroy={(val) => loggerUri.set(val)}
          />
        </div>
      </div>
      <div class="flex items-center my-8 w-14/15 mx-auto">
        <div class="w-1/5">
          <label
            class="block text-base text-gray-500 font-bold md:text-right mb-1
            md:mb-0 pr-4"
            for="payetihad-uri"
          >
            Payetihad URI
          </label>
        </div>
        <div class="w-12/15">
          <input
            class="bg-gray-200 appearance-none border-2 border-gray-200 rounded
            w-full py-2 px-4 text-gray-500 leading-tight focus:outline-none
            focus:bg-white focus:border-blue-500"
            id="payetihad-uri"
            type="text"
            value={$payetihadUri}
            use:onElementDestroy={(val) => payetihadUri.set(val)}
          />
        </div>
      </div>

      <div class="flex items-center my-8 w-14/15 mx-auto">
        <div class="w-1/5">
          <label
            class="block text-base text-gray-500 font-bold md:text-right mb-1
            md:mb-0 pr-4"
            for="application-flow"
          >
            Application Flow
          </label>
        </div>
        <div class="w-12/15">
          <select
            class="bg-gray-200 appearance-none border-2 border-gray-200 rounded
            w-full py-2 px-4 text-gray-500 leading-tight focus:outline-none
            focus:bg-white focus:border-blue-500"
            id="application-flow"
            bind:value={$applicationFlow}
          >
            {#each Object.entries(ApplicationFlow) as [name, value]}
              <option {value}>{prettifyAllCaps(name)}</option>
            {/each}
          </select>
        </div>
      </div>

      <div class="flex items-center my-8 w-14/15 mx-auto">
        <div class="w-1/5">
          <label
            class="block text-base text-gray-500 font-bold md:text-right mb-1
            md:mb-0 pr-4"
            for="eligibilityChecks"
          >
            Eligibility Checks
          </label>
        </div>
        <div class="w-12/15">
          <div class="mx-2">
            <span
              class="text-white cursor-pointer font-bold py-1 px-2 rounded-full
              text-xs"
              class:bg-palmGreen={$enableEligibilityCheck}
              class:bg-saffron={!$enableEligibilityCheck}
              on:click={() => {
                enableEligibilityCheck.set(!$enableEligibilityCheck);
              }}
            >
              {#if $enableEligibilityCheck}Enabled{:else}Disabled{/if}
            </span>
          </div>
        </div>
      </div>

      <div class="flex items-center my-8 w-14/15 mx-auto">
        <div class="w-1/5">
          <label
            class="block text-base text-gray-500 font-bold md:text-right mb-1
            md:mb-0 pr-4"
            for="hotkeys"
          >
            Hotkeys
          </label>
        </div>
        <div class="w-12/15">
          <div class="mx-2">
            <span
              class="text-white cursor-pointer font-bold py-1 px-2 rounded-full
              text-xs"
              class:bg-palmGreen={$enableHotkeys}
              class:bg-saffron={!$enableHotkeys}
              on:click={() => {
                enableHotkeys.set(!$enableHotkeys);
              }}
            >
              {#if $enableHotkeys}Enabled{:else}Disabled{/if}
            </span>
          </div>
        </div>
      </div>

      <div class="flex items-center my-8 w-14/15 mx-auto">
        <div class="w-1/5">
          <label
            class="block text-base text-gray-500 font-bold md:text-right mb-1
            md:mb-0 pr-4"
            for="ignoreMissingDevices"
          >
            Ignore Missing Devices
          </label>
        </div>
        <div class="w-12/15">
          <div class="mx-2">
            <span
              class="text-white cursor-pointer font-bold py-1 px-2 rounded-full
              text-xs"
              class:bg-palmGreen={$ignoreMissingComponents}
              class:bg-saffron={!$ignoreMissingComponents}
              on:click={() => {
                ignoreMissingComponents.set(!$ignoreMissingComponents);
              }}
            >
              {#if $ignoreMissingComponents}Enabled{:else}Disabled{/if}
            </span>
          </div>
        </div>
      </div>
    {:else if activeTab === 'Inputs'}
      {#if $errorModal !== null && 'override' in $errorModal}
        <DebugBarcodeInput />
      {:else if $awaitingTimeoutRecovery}
        {#if $userInitiationMethod === TransactionInitiator.PNR}
          <DebugPNRInput />
        {:else if $userInitiationMethod === TransactionInitiator.BOARDING_PASS}
          <DebugBarcodeInput />
        {:else if $userInitiationMethod === TransactionInitiator.PASSPORT}
          <DebugMRZInput />
        {/if}
      {:else if $location === '/'}
        <div class="flex items-center mt-8 w-14/15 mx-auto">
          <div class="w-1/5">
            <label
              class="block text-base text-gray-500 font-bold text-right mb-1
              mb-0 pr-4"
              for="inputSelect"
            >
              Input Select
            </label>
          </div>
          <div class="w-12/15">
            <select
              class="bg-gray-200 appearance-none border-2 border-gray-200
              rounded w-full py-2 px-4 text-gray-500 leading-tight
              focus:outline-none focus:bg-white focus:border-blue-500"
              name="inputSelect"
              id="inputSelect"
              bind:value={inputSelect}
            >
              {#each Object.entries(InputOptions) as [name, value]}
                <option {value}>{prettifyAllCaps(name)}</option>
              {/each}
            </select>
          </div>
        </div>
        {#if inputSelect === InputOptions.PNR}
          <DebugPNRInput />
        {:else if inputSelect === InputOptions.BOARDING_PASS}
          <DebugBarcodeInput />
        {:else if inputSelect === InputOptions.PASSPORT_MRZ}
          <DebugMRZInput />
        {/if}
      {:else if $location === '/scan-passport' || $location === '/passport-to-camera'}
        <DebugMRZInput />
      {:else if $location === '/porter-checking-your-bag' || $location === '/porter-checking-your-bag-hybrid' }
        <div class="text-center mt-8 w-14/15 mx-auto">
          <span
            class="text-white cursor-pointer font-bold py-1 px-2 rounded-full
            text-xs bg-blue-600"
            on:click={() => {
              setIsLifted();
            }}
          >
            Lift bag from scales
          </span>
        </div>
      {:else if $currentBagIndex !== 0}
        {#if (currentBag.isReadyForInjection()) && 
             (get(location) == '/place-passenger-bag' || get(location) == '/dispatching-bag')}
          <div class="text-center mt-8 w-14/15 mx-auto">
            <span
              class="text-white cursor-pointer font-bold py-1 px-2 rounded-full
              text-xs bg-blue-600"
              on:click={() => {
                if (get(location) == '/dispatching-bag') {
                  sbdState.set(SBDStates.READY_TO_INJECT);
                  transition_READY_TO_INJECT__TAG_ACTIVATED();
                  setTimeout(() => {
                    bagInjected.set(true);
                  }, 8000);
                } else {                
                  bagInjected.set(true);
                }
              }}
            >
              Inject into BHS
            </span>
          </div>
          {:else if get(location) == '/place-passenger-bag'}
            <DebugBag bagNumber={$currentBagIndex} />
          {:else}
            <DebugBag bagNumber={$currentBagIndex} />
        {/if}
      {/if}
      <div class="h-8" />
    {:else if activeTab === 'Responses'}
      <Json heading="booking" json={$booking} />
      <Json heading="bag tags" json={$tagNumbers} />
    {:else if activeTab === 'Screens'}
      <div class="mx-auto my-8 w-14/15">
        <p>Click below to navigate directly to specific application screens.</p>
        <p>
          Note, this is for design verification purposes only. Screens will not
          be fully functional.
        </p>
        <div class="w-4/5 mx-auto my-4">
          <ul class="list-disc">
            {#each routesArray as route}
              <li>
                <button
                  class="hover:text-gold-100"
                  role="button"
                  on:click={() => {
                    push(route);
                  }}
                >
                  {routeName(route)}
                </button>
              </li>
            {/each}
          </ul>
        </div>

        <p>Error Modals:</p>
        <div class="w-4/5 mx-auto my-4">
          <ul class="list-disc">
            <li>
              <button
                class="hover:text-gold-100"
                role="button"
                on:click={() => {
                  setErrorModal(null);
                }}
              >
                Close open modal
              </button>
            </li>
            {#each Object.entries(ErrorModals) as [name, modal]}
              <li>
                <button
                  class="hover:text-gold-100"
                  role="button"
                  on:click={() => {
                    setErrorModal(modal);
                  }}
                >
                  {prettifyAllCaps(name)}
                </button>
              </li>
            {/each}
          </ul>
        </div>
      </div>
    {/if}
  </div>
</ModalBare>
