<script>
  import { _ } from 'svelte-i18n';
  import { get } from 'svelte/store';
  import { onDestroy, onMount } from 'svelte';
  import { push } from 'svelte-spa-router';
  import {
    addBag,
    booking,
    currentBag,
    currentBagIndex,
    currentPassenger,
    currentTotalWeight,
    featureFlags,
    headPassenger,
    isBagsPaymentSuccessful,
    isFinalBag,
    setErrorModal,
    setInPaymentFlow,
    wizardPosition,
    excessWeightEachBagInPieceConcept
  } from '../../js/stores';
  import { collectExcessOnLastBag } from '../../js/stores/bags';
  import { readyToReceive, sbdPausedPendingExcessBaggage } from '../../js/stores/sbdState';
  import {
    BagWeightMax,
    BagWeightWarningPercent,
    FeatureFlags,
    WizardPosition,
  } from '../../js/const';
  import { ErrorModals } from '../../js/const/errorModals';
  import flightdeck from '../../js/services/flightdeck';
  import logger from '../../js/logger';

  import BaggageAllowanceExceededHybrid from '../components/modal/BaggageAllowanceExceededHybrid.svelte';
  import BaggageAllowancePercentageReachedHybrid from '../components/modal/BaggageAllowancePercentageReachedHybrid.svelte';
  import Content from '../components/Content.svelte';
  import Footer from '../components/Footer/index.svelte';
  import Header from '../components/Header/index.svelte';
  import VerificationInfo from '../components/VerificationInfo.svelte';
  
  let isPcBaggage = null;
  let showBaggageAllowanceExceededModal = null;
  let hasShownBaggageAllowanceExceededModal = null;
  let showBaggageAllowancePercentageReachedModal = null;
  let showTripExtrasPaymentModal = null;
  let unsubscribeCurrentBag = () => {};
  let isExcessBag = false;

  wizardPosition.set(WizardPosition.SECURITY);

  /**
   * Custom function that calculates the percent of a number.
   *
   * @param Int percent The percent that you want to get.
   * @param Int|int num The number that you want to calculate the percent of.
   * @returns {number}
   */
  function calculateWarningWeight(percent) {
    return Math.round((percent / 100) * booking.totalCombinedAllowance());
  }

  function getExcessWeightOfEachBag() {
    let allowedWeight = booking.getPieceWeights()[$currentBagIndex - 1];
    let bagWeight = get(currentBag).weight;
    logger.info(`Allowed piece weight to passenger is: ${allowedWeight}, Current bag weight is: ${bagWeight}`);

    // check if array excessWeightEachBagInPieceConcept already has info for current bag
    let isCurrentBagInArray = false;
    let excessPieceWeightArray = get(excessWeightEachBagInPieceConcept);
    if (excessPieceWeightArray && excessPieceWeightArray.length > 0) {          
      excessPieceWeightArray.forEach(i => {
        if (i.bagIndex === ($currentBagIndex - 1)) {
          isCurrentBagInArray = true;
          i.excess = bagWeight - allowedWeight
        }
      });
    } else {
      excessPieceWeightArray = [];
    }
    if (!isCurrentBagInArray) {
      excessPieceWeightArray.push({  bagIndex: $currentBagIndex - 1, excess: bagWeight - allowedWeight });
    }
    excessWeightEachBagInPieceConcept.set(excessPieceWeightArray); 
  }

  /** Subscription to currentBag. */
  function currentBagSubscription(bag) {
    const shouldProcessBag = bag && bag.settled && bag.weight;
    isPcBaggage = booking.getBaggageUnit() === 'pc';
    logger.info(`currentBag.subscribe(). bag: ${JSON.stringify(bag)}; bag.settled: ${bag && bag.settled}`);

    if (shouldProcessBag) {
      bag = currentBag; // Use the store, not the store's value

      // Return to bag drop case
      if (booking.totalNumberOfActivatedBagTags() > 0) {
        isExcessBag = booking.totalCombinedAllowance() - booking.totalNumberOfActivatedBagTags() <= 0;          
      } else {
        // Non-return to bag drop case
        isExcessBag = booking.totalCombinedAllowance() - $currentBagIndex < 0;
      }

      // here piece or weight market doesnt matter
      // if its over 23 , we will charge them extra weight (in piece also)
      let hasExceededWeightMaximum = currentBag.failsWeight();

      const hasExceededWarningThreshold =
        (currentTotalWeight() + booking.totalWeightUsedInPreviousTransaction()) >=
        calculateWarningWeight(BagWeightWarningPercent);

      updateSbdPausedPendingExcessBaggage(
        bag,
        hasExceededWarningThreshold,
      );

      if (hasExceededWeightMaximum) {
        logger.warn('BAG_WEIGHT_ERROR');
        setErrorModal(ErrorModals.BAG_WEIGHT_ERROR);
        flightdeck.overweightSingleBag(
          $currentBag,
          $currentBagIndex,
          currentBag.amountWeightOverSingleBagMaximum(),
          BagWeightMax,
        );
      } else if (bag.failsTotalWeight() && !isPcBaggage) {
        if ($isBagsPaymentSuccessful) {
          // if the payment has already been corrected, that means the Error events
          // received from the front end app were after the bag weight
          logger.info(
            `Excess bag payment has already been made. Exiting the subscription.`,
          );
          sbdPausedPendingExcessBaggage.unpause(
            'Inside Excess bag payment section. Payment has already been made. Unpausing the sbpState',
          );
        } else if (!$currentBag.inPaymentFlow) {
          setInPaymentFlow(true);
          showBaggageAllowanceExceededModal = true;
          logger.info(
            'Bag exceeds total weight allowance. ' +
              'Displaying baggage allowance exceeded modal.',
          );
        }
      }  else if (isPcBaggage) {
        logger.info(`Piece - isPcBaggage is: ${isPcBaggage}, $isBagsPaymentSuccessful is: ${$isBagsPaymentSuccessful}, $currentBag.inPaymentFlow is: ${$currentBag.inPaymentFlow}, isFinalBag() is: ${isFinalBag()}, $currentBag.weight is: ${$currentBag.weight}, $currentBag.isAllowableExtra is: ${$currentBag.isAllowableExtra}, get(collectExcessOnLastBag) is ${get(collectExcessOnLastBag)}, showBaggageAllowanceExceededModal is ${showBaggageAllowanceExceededModal}, hasShownBaggageAllowanceExceededModal is ${hasShownBaggageAllowanceExceededModal}`);

        if ($isBagsPaymentSuccessful || $currentBag.isAllowableExtra) {
          sbdPausedPendingExcessBaggage.unpause('Payment is made or Agent has overridden excess. Unpausing the sbd State');
          return;
        }

        if (currentBag.failsPieceWeight(booking.getPieceWeights()[$currentBagIndex - 1])) {
          logger.info('failsPieceWeight is true. We need to find whats the excess weight of the bag.');
          collectExcessOnLastBag.set(true); // for piece - excess collection will only be set to true in two cases 1) if selected No of bags > allowed bags or 2) any passenger bag is > allowed piece weight
          getExcessWeightOfEachBag();
          // showing excess baggage modal 
          if (!hasShownBaggageAllowanceExceededModal) {
            showBaggageAllowanceExceededModal = true;
            hasShownBaggageAllowanceExceededModal = true;
            return;
          }
        }
          
        if (!$currentBag.inPaymentFlow && get(currentBag).weight > 0 && get(collectExcessOnLastBag)) {
          logger.info(`Bag is not in payment flow. Setting it to payment flow and showing excess modal.`);
          showBaggageAllowanceExceededModal = true;
          hasShownBaggageAllowanceExceededModal = true;
          setInPaymentFlow(true);
          return;
        }

        if (isFinalBag() && get(collectExcessOnLastBag)) {
          logger.info(`Piece - It is the final bag and we need to collect excess and hasShownBaggageAllowanceExceededModal is: ${hasShownBaggageAllowanceExceededModal}`);
          if (!hasShownBaggageAllowanceExceededModal) {
            showBaggageAllowanceExceededModal = true;
            hasShownBaggageAllowanceExceededModal = true;
          }
          return;
        }

        // if none of above is true, means we are good to print the bag tag
        logger.info(`calling advanceToNextScreen() to print the bag tag`);
        advanceToNextScreen();

      } else if (
        hasExceededWarningThreshold &&
        !isFinalBag() &&
        !isPcBaggage &&
        featureFlags.isEnabled(FeatureFlags.EXCESS_BAGGAGE)
      ) {
        showBaggageAllowancePercentageReachedModal = true;
        logger.info(
          'Bag weight exceeds allowance warning percentage: ' +
            `${BagWeightWarningPercent}%`,
        );
      } else {
        advanceToNextScreen();
      }
    }
  }

  function updateSbdPausedPendingExcessBaggage(
    bag,
    hasExceededWarningThreshold,
  ) {
    if (isPcBaggage) {
      logger.info(`In Piece Market - isPcBaggage is: ${isPcBaggage}, $isBagsPaymentSuccessful is: ${$isBagsPaymentSuccessful}, $currentBag.inPaymentFlow is: ${$currentBag.inPaymentFlow}, isFinalBag() is: ${isFinalBag()}, $currentBag.weight is: ${$currentBag.weight}, $currentBag.isAllowableExtra is: ${$currentBag.isAllowableExtra}, showBaggageAllowanceExceededModal is: ${showBaggageAllowanceExceededModal}, hasShownBaggageAllowanceExceededModal is ${hasShownBaggageAllowanceExceededModal}`);
      
      // we do not want to listen to the 
      if (showBaggageAllowanceExceededModal ||
        (!$isBagsPaymentSuccessful && $currentBag.inPaymentFlow && isFinalBag() && $currentBag.weight > 0 && !$currentBag.isAllowableExtra)) {
        sbdPausedPendingExcessBaggage.pause('Inside updateSbdPausedPendingExcessBaggage - either showBaggageAllowanceExceededModal is true or  Bag is in payment flow, it is the final bag, the payment is not made . pausing the SBD State');
        return; 
      }

      // possibly need to add is 1) $currentBag.inPaymentFlow == false AND 2) $currentBag.inPaymentFlow == true && !isFinalBag() ;
      if ((!currentBag.failsPieceWeight(booking.getPieceWeights()[$currentBagIndex - 1])) && // weight of this piece/bag < allowed weight of a Piece i.e. 23 or 32 (determined by other pieces) 
          (!$currentBag.inPaymentFlow || // bag is not in payment flow OR
          ($currentBag.inPaymentFlow && !isFinalBag() && !showBaggageAllowanceExceededModal) || // bag is in payment flow and its not the last bag and baggageAllowanceExceeded is not visible 
          ($currentBag.inPaymentFlow && isFinalBag() && ($isBagsPaymentSuccessful || $currentBag.isAllowableExtra)) // OR bag is in payment flow and it is the final bag and either payment is sucessful or agent has overridden
          ))       
      {
        sbdPausedPendingExcessBaggage.unpause('Piece-concept - This piece/bag does not exceed weight, hence unpausing sbd state');                              
        return;
      }
    } else {
      if (
        !bag.failsTotalWeight() &&
        !(hasExceededWarningThreshold && !isFinalBag())
      ) {
        sbdPausedPendingExcessBaggage.unpause(
          `This bag does not exceed the total weight. ` +
            `If it has exceeded the percentage warning threshold, it is on the ` +
            `final bag, so no warning required. ` +
            `Check values: ` +
            `failsTotalWeight: ${bag.failsTotalWeight()}; ` +
            `hasExceededWarningThreshold: ${hasExceededWarningThreshold}; ` +
            `isFinalBag: ${isFinalBag()}`,
        );
      }
    }
  }
  
  /** Move to the next screen. */
  function advanceToNextScreen() {
    push('/porter-printing-bag-tag-hybrid');
  }

  onMount(() => {
    isPcBaggage = booking.getBaggageUnit() === 'pc';
    showBaggageAllowanceExceededModal = false;
    showBaggageAllowancePercentageReachedModal = false;
    showTripExtrasPaymentModal = false;
    
    addBag();

    sbdPausedPendingExcessBaggage.pause('SBD Paused...');
    
    try {
      readyToReceive(); // enable the insertion belt.
    } catch (e) {
      logger.info('Inside PorterPlacePassengerBagHybrid..onMount().. failed readyToReceive().. handling the exception to continue...');
      // in case the Embross hardware is not available....
    }

    unsubscribeCurrentBag = currentBag.subscribe(currentBagSubscription);  
  });

  onDestroy(() => {
    unsubscribeCurrentBag();
  });
</script>

<BaggageAllowanceExceededHybrid
  bind:showModal={showBaggageAllowanceExceededModal}
/>

<BaggageAllowancePercentageReachedHybrid
  bind:showModal={showBaggageAllowancePercentageReachedModal}
/>

<Header hasHomeButton={false} />

<Content>
  <span slot="heading">{$_('placeBag.heading')}</span>
  <span slot="text">{$_('placeBag.textPbd')}</span>

  <div slot="main">
    <div class="mt-16 mx-auto ratio ratio--9:16 w-82">
      <img
        class="ratio__content"
        src="images/porterBagDrop.webp"
        alt="Porter Bag Drop."
      />
    </div>

    {#if $currentPassenger}
      <VerificationInfo isPcBaggage={isPcBaggage} />
    {:else}
      <VerificationInfo isPcBaggage={isPcBaggage} />
    {/if}
  </div>
</Content>

<Footer />
