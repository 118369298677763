/* Store state relating to bags. */
import { derived, get, writable } from 'svelte/store';
import { integratedCatalogue } from './integratedCatalogue';
import {
  BagLengthMax,
  BagHeightMax,
  BagWidthMax,
  BagWeightMax,
  BagWeightHeavy,
  BagSettledTimeout,
  CussStatusCodes,
  ApplicationFlow,
  PieceUnit,
  WeightUnit,
} from '../const';
import { ErrorModals } from '../const/errorModals';
import flightdeck from '../services/flightdeck';
import { booking } from './booking';
import { numbersEqualWithTolerance, timeoutPromise } from '../utils';
import { currentPassenger } from './bagTags';
import logger from '../logger';
import { passengerBagCounts } from './passengerBagCounts';
import { receipt } from './receipt';
import { resetGlobalTimeout } from './timeout';
import { resettable } from './extensions/resettable';
import switchboardClient, {
  raiseErrorOnBadResponse,
} from '../services/switchboard';
import { applicationFlow, baseStationCode } from './config';
import { transactionId } from './transactionId';
import hasRecordInUseError from '../../js/recordInUseError';
import { setErrorModal } from './errorModal';

export const isStaffForceAcceptanceEnforced = resettable(false);
             
export const acceptedToPay = resettable(false);

export const isPaymentDeclined = resettable(false);

export const agentHasOverridden = resettable(false);

export const isBagsPaymentSuccessful = resettable(false);

export const uniqueBagIdentifierList = resettable([]);

// List of bag UBIS's that are marked as priority, eg by SSR PRIO
export const uniqueBagIdentifierPriorityList = resettable([]);

export const collectExcessOnLastBag = resettable(false);

export const numberOfBagsSelected = resettable(0);

export const excessPieceCount = resettable(0);

export const excessPieceApproxCharges = resettable(0);

export const excessPieceCurrency = resettable('AED');

export const excessWeightEachBagInPieceConcept = resettable([]);

export function setPaymentDeclined(status) {
  isPaymentDeclined.set(status);
}

export function setAgentHasOverridden(status) {
  agentHasOverridden.set(status);
}

/**
 * Set the bag payment status
 *
 *  @param {boolean} status - Indicates if paid or not
 */
export function setIsBagsPaymentSuccessful(status) {
  isBagsPaymentSuccessful.set(status);
}

export function setAcceptedToPay(status) {
  acceptedToPay.set(true);
}

/** A bag being presented by the guest. */
class Bag {
  constructor(
    length = 0,
    width = 0,
    height = 0,    
    weight = 0,
    isAllowableExtra = false,
    isLifted = false,
    inPaymentFlow = false,    
    bagTagID = null,
    bagTagActivated = false,
    rawWeight = 0,
    settled = false,
    bagTagNumberPrefix = null,
    isPaymentSuccess = false
  ) {
    Object.assign(this, {
      length,
      width,
      height,
      weight,
      isAllowableExtra,
      isLifted,
      inPaymentFlow,
      bagTagID,
      bagTagActivated,
      rawWeight,
      settled,
      bagTagNumberPrefix,
      isPaymentSuccess
    });
  }
}

/** To check if the getBaggage allowance service has been called or not */
export const isGetBagAllowanceServiceCalled = resettable(false);

/** Is set to true on injection into BHS. */
export const bagInjected = resettable(false);

/** Bags as presented by the guest. */
export const bags = resettable([]);

/** The bag number out of the total bags presented by guests.
 *
 * The first bag has an index of '1'
 */
export const currentBagIndex = derived(bags, ($bags) => $bags.length);

let bagSettledTimeoutId = null;

/** The bag currently being verified. */
export const currentBag = {
  ...derived(bags, ($bags) => ($bags.length ? $bags[$bags.length - 1] : {})),
  ...{
    /** Return true if data is available and it fails. */
    failsDimensions() {
      const bag = get(this);
      if (
        bag.length &&
        bag.height &&
        bag.width &&
        (bag.length > BagLengthMax ||
          bag.height > BagHeightMax ||
          bag.width > BagWidthMax)
      ) {
        return true;
      }
      return false;
    },

    /** Return amount that bag is over the maxmimum weight limit for a bag. */
    amountWeightOverSingleBagMaximum() {
      const amountOver = get(this).weight - BagWeightMax;
      return amountOver >= 0 ? amountOver : 0;
    },

    /** Return amount that bag is over the maxmimum piece weight limit for a bag. */
    amountPieceWeightOverSingleBagMaximum(weight) {
      const amountOver = get(this).weight - weight;
      return amountOver >= 0 ? amountOver : 0;
    },

    /** Fail when current bag exceeds per bag weight limit. */
    failsWeight() {
      return this.amountWeightOverSingleBagMaximum() > 0;
    },

    /** Fail when current bag exceeds per bag piece weight limit. */
    failsPieceWeight(weight) {
      return this.amountPieceWeightOverSingleBagMaximum(weight) > 0;
    },

    /** Fail when the weight of the current bag puts total weight over limit. */
    failsTotalWeight() {
      return currentTotalExcessWeight() > 0;
    },

    isNumberOfBagsInStoreOverPieceAllowance() {
      const numberOfBags = get(bags).length;
      return (
        booking.isPieceBaggage() &&
        numberOfBags > (booking.totalCombinedAllowance() - booking.totalNumberOfActivatedBagTags())
      );
    },

    /** get the current bag weight */
    getBagWeight() {
      const bag = get(this);
      return bag.weight;
    },

    /**
     * Calculates the payment amount based on the transaction excess weight.
     *
     * @returns {number} Cost of the excess weight
     */
    excessWeightCost() {
      const totalExcessWeight = currentTotalExcessWeight();
      const excessBaggageRate =
        integratedCatalogue.getExcessBaggageRate(totalExcessWeight);
      
      logger.log(`Price from integrated catalogue: ${excessBaggageRate?.price}`);
      logger.log(`Slab from integrated catalogue is : ${excessBaggageRate?.slab}`);
      
      let totalPrice = 0;
      
      if (booking.totalFrequentFlyerAllowance() > 0) {
        // incase any passenger has Frequent Flyer allowance
        totalPrice = Math.ceil(totalExcessWeight / 5) * excessBaggageRate?.price;
      } else {
        // for cases where no passenger has Frequent Flyer allowance
        totalPrice = excessBaggageRate?.slab * excessBaggageRate?.price;
      }

      // If apply rounded if currency is AED
      if (excessBaggageRate?.currency == 'AED') {
        totalPrice = Math.ceil(totalPrice / 10) * 10;
      }

      return Number(totalPrice);
    },

    excessCost() {      
      return booking.isPieceBaggage()
        ? get(excessPieceApproxCharges)
        : this.excessWeightCost();
    },

    /**
     * Fail when the bag tag number entered does not match a valid bag tag.
     */
    failsBagTagNumber() {
      const bag = get(this);
      const availableBagTags = currentPassenger.availableBagTags();
      // Embross CUSS would read a bag.bagTagID of 10 characters, we need
      // to trim the first 4 characters to meet amadeus bag tag number length
      let tagNumber = '000000';
      if (bag && bag.bagTagID) {
        if (bag.bagTagID.length > 6 && bag.bagTagID.length === 10) {
          tagNumber = bag.bagTagID.substring(4,10);
        } else if (bag.bagTagID.length > 0 && bag.bagTagID.length === 6) {
          tagNumber = bag.bagTagID;
        }
      }
      const bagIsAvailable = availableBagTags.includes(tagNumber);

      if (bag.bagTagID && !bagIsAvailable) {
        return true;
      }
      return false;
    },

    /** Checks all values are input. */
    hasAllValues() {
      const bag = get(this);
      return Boolean(
        bag.length && bag.width && bag.height && bag.weight && bag.bagTagID,
      );
    },

    /**
     * Checks current bag's values are valid.
     *
     * @param {boolean} usePaymentFlowRule - Indicates if payment-flow rule
     * should be used. Payment-flow rule is: if the bag fails total weight
     * check but it is in payment flow, it is still considered as ready for
     * injection.
     * @returns {boolean} - Indicates if the bag is ready for injection.
     */
    isReadyForInjection(usePaymentFlowRule = false) {
      const bag = get(this);
      
      const isLastBag = isFinalBag();

      if (booking.isPieceBaggageWithOutTranslation()) {
        return (
          this.hasAllValues() &&
          !this.failsDimensions() &&
          !this.failsWeight() &&
          !this.failsBagTagNumber() &&
          (!isLastBag || // its not last bag return true i.e. bag is ready for injection is all above are true
          (isLastBag && !get(collectExcessOnLastBag)) || // its last bag && excess collection is not required return true i.e. bag is ready for injection is all above are true
          (isLastBag && get(collectExcessOnLastBag) && get(isBagsPaymentSuccessful)) || 
          (isLastBag && get(collectExcessOnLastBag) && bag.isAllowableExtra)) // its last bag && excess collection is required and payment is Not done return false i.e. bag is ready for injection is all above are true
        );
      } else {      
        return (
          this.hasAllValues() &&
          !this.failsDimensions() &&
          !this.failsWeight() &&
          !this.failsBagTagNumber() &&
          (!this.failsTotalWeight() ||
          (usePaymentFlowRule && bag.inPaymentFlow) ||         
            get(isBagsPaymentSuccessful))
          /* adding "isBagsPaymentSuccessful" just to make sure that
            payment has been made so if TotalWeight fails, we let the user
            move forward */
        );
      }
    },

    /**
     * Activate the bag's bag tag with Sabre.
     * @returns {promise<boolean>} - A promise whose result indicates whether
     * the bag tag has been activated successfully.
     */
    async activate(totalWeight, productsList, currentBagWeight, bagTagObject, activateBagTag, serviceRetryCount = 0) {
      const bag = get(this);
      logger.info(
        `Request activateBagTag. (bagTagID: ${bag && bag.bagTagID})`,
      );

      let isStaffForceAcceptanceRequired = get(isStaffForceAcceptanceEnforced);
      logger.log('Staff force acceptance is: ', isStaffForceAcceptanceRequired);

      // testing update baggage information 
      let input = {
        totalNumberOfBags: passengerBagCounts.totalBags().toString(),
        totalWeight: totalWeight.toString(),
        unitQualifier: 'K',
        bagReferenceNumber: bagTagObject.baggageReference,
        marketingCarrier: productsList[0].productFlightDetails.marketingCarrier,
        bagTagNumber: bagTagObject.bagTagNumbers,
        individualBagWeight: currentBagWeight.toString(),
        individualBagUnitQualifier: 'K',
        individualBagUniqueIdentifier: bagTagObject.uniqueBagIdentifier,
        flightNumber: productsList[0].productFlightDetails.flightNumber,
        departureDate: productsList[0].productFlightDetails.departureDate,
        boardPoint: productsList[0].productFlightDetails.boardPoint,
        companyNumber: bagTagObject.issuingCarrierNumber,
        company: bagTagObject.issuingCarrierCode,
        offPoint: bagTagObject.finalDestination,
        activateBagTag: activateBagTag,
        isStaffForceAcceptanceRequired: isStaffForceAcceptanceRequired,
        baseStation: baseStationCode,
        transactionId: get(transactionId)        
      }

      logger.info(`Input to UpdateBaggageInformation is: ${JSON.stringify(input)}`);

      const activateTagTask = switchboardClient.updateBaggageInformation(input)
        .then((response) => {
          logger.info('Received activateBagTag response from updateBaggageInformation:', JSON.stringify(response));
          const isUpdateBaggageSuccess = response?.data?.updateBaggageInformation?.isUpdateSuccessful;

          const errorMessages = response?.data?.updateBaggageInformation?.errorMessages;
          if (errorMessages && errorMessages?.length > 0) {
            logger.error('Could not update baggage information.');
            
            // check if record in use error is received
            if (hasRecordInUseError(errorMessages)) {
              logger.info('Record in use error received from updateBaggageInformation. waiting three seconds to recall the service');
              return 'retry';
            }  
            
            errorMessages.forEach((error) => {
              logger.error(error?.message);
            });
            logger.info('returning false from update baggage information error Messages...');
            return 'false';
          }

          const unpaidExcessExists = response?.data?.updateBaggageInformation?.unpaidExcessBaggageExists;
          if (unpaidExcessExists) {
            logger.info('unpaid excess exists for customer');
            // if agent has already overriden excess, ignore unpaid Excess warning
            if (bag.isAllowableExtra) {            
              logger.info('App has already detected excess and isAllowableExtra is true means agent has overriden excess');
            } else if (bag.inPaymentFlow) {             
              logger.info('app has already detected excess and inPaymentFlow is already true');
            } else if (get(collectExcessOnLastBag) === true) {
              logger.info('It is piece market, user selected more bags than allowed and has already accepted to pay.');
            } else {
              flightdeck.updateBaggageInformationFailedUnpaidExcessExists();              
              logger.info('returning false from update baggage information Unpaid excess exist...');
              return 'false';
            }
          }          
          
          if (activateBagTag && isUpdateBaggageSuccess) {
            setBagTagActivated();
          }
          setBagTagPrefix(bagTagObject.bagTagNumberPrefix);
          return 'true';
        }).catch((error) => {
          logger.warn(`Failed activateBagTag from updateBaggageInformation: ${error}.`);
          return false;
        });

      return activateTagTask;
    },

    /** Store in the receipt */
    toReceipt() {
      const bag = get(this);
      logger.info(
        `toReceipt(). ` +
        `Will add bag (bagTagID: ${bag && bag.bagTagID}) to receipt.`,
      );

      let formattedBagTagID = bag.bagTagID; 
      if (bag && bag.bagTagID) {
        if (bag.bagTagID.length > 6 && bag.bagTagID.length === 10) {
          formattedBagTagID = bag.bagTagID.substring(4,10);
        } else if (bag.bagTagID.length > 0 && bag.bagTagID.length === 6) {
          formattedBagTagID = bag.bagTagID;
        }
      }

      receipt.add(
        get(currentPassenger).passengerID,
        bag.weight,
        new Date(),
        formattedBagTagID, //bag.bagTagID, // TODO: Check if appending Prefix to bagTagNumber breaks something
        bag.bagTagNumberPrefix
      );
    },
  },
};

/** function to check if the bag is within the 23 to 32KG */
export function weightIsHeavy(weight) {
  return weight >= BagWeightHeavy && weight <= BagWeightMax;
}

/** Call when the guest presents a new bag. */
export function addBag() {
  bags.set([...get(bags), new Bag()]);
}

/** Returns the remaining bags. */
export function getRemainingTotalBags(includeCurrentBag = false) {
  let remainingBags = null;

  if (includeCurrentBag || !get(currentBag).settled) {
    remainingBags = currentPassenger.remainingBagsCount();
  } else {
    remainingBags = currentPassenger.remainingBagsCount() - 1;
  }

  return remainingBags;
}

/** Returns the remaining total weight. */
export function getRemainingTotalWeight(includeCurrentBag = false) {
  let remainingWeight = null;

  if (includeCurrentBag) {
    remainingWeight = booking.totalCombinedAllowance() - currentTotalWeight() - booking.totalWeightUsedInPreviousTransaction();
  } else {
    remainingWeight =
      booking.totalCombinedAllowance() -
      (receipt.totalWeight() + (get(currentBag).weight || 0) + booking.totalWeightUsedInPreviousTransaction());
  }

  return remainingWeight;
}

export function resetCurrentBag() {
  const currentBags = get(bags);
  bags.set([...currentBags.slice(0, currentBags.length - 1), new Bag()]);
}

/** Make changes to the bag currently being presented. */
export function updateCurrentBag({
  length,
  width,
  height,
  weight,
  isAllowableExtra,
  isLifted,
  inPaymentFlow,
  bagTagID,
  bagTagActivated,
  rawWeight,
  settled,
  bagTagNumberPrefix,
  isPaymentSuccess
}) {
  const bag = get(currentBag);
  if (!bag) {
    return;
  }
  Object.assign(bag, {
    length: Number(length),
    width: Number(width),
    height: Number(height),
    weight: Math.floor(Number(weight)),
    isAllowableExtra: Boolean(isAllowableExtra),
    isLifted: Boolean(isLifted),
    inPaymentFlow: Boolean(inPaymentFlow),
    bagTagID,
    bagTagActivated: Boolean(bagTagActivated),
    rawWeight: Number(rawWeight),
    settled: Boolean(settled),           
    bagTagNumberPrefix: bagTagNumberPrefix,
    isPaymentSuccess: Boolean(isPaymentSuccess)
  });

  logger.info('Updated bag:', JSON.stringify(bag));

  bags.set(get(bags)); // Trigger reactivity

  resetGlobalTimeout();
}

/** Total transaction weight including current bag. */
export function currentTotalWeight() {
  let total = 0;

  for (let i = 0; i < get(bags).length; i++) {
    if (!get(bags)[i].isAllowableExtra) {
      total += get(bags)[i].weight;
    }
  }
 
  return total;
}

/** Excess of transaction weight above total transaction allowance. */
export function currentTotalExcessWeight() {  
  const currentTotalExcess = (currentTotalWeight() + booking.totalWeightUsedInPreviousTransaction()) - booking.totalAllowanceByType();
  return currentTotalExcess;
}



export function getPieceConceptExcessNumberOfBags(selectedNumberOfBags = 0) {
  if (selectedNumberOfBags === 0) {
    selectedNumberOfBags = get(excessPieceCount);
  }
  
  let excessNumberOfBags = 0;
  // if activated bag tags received from amadeus is >= booking baggage allowance, means 
  // all the selected number of bag are Excess Bags
  if (booking.totalNumberOfActivatedBagTags() >= booking.totalCombinedAllowance()) {
    excessNumberOfBags = selectedNumberOfBags;
  } else if (booking.totalNumberOfActivatedBagTags() < booking.totalCombinedAllowance()) {
    excessNumberOfBags = selectedNumberOfBags - (booking.totalCombinedAllowance() - booking.totalNumberOfActivatedBagTags());    
  }    

  if (excessNumberOfBags > 0) {
    collectExcessOnLastBag.set(true);
    excessPieceCount.set(excessNumberOfBags);
  }


  return excessNumberOfBags;
}




// this may not work now, when we do return to bad drop
// since this time a person may select 1 bag , and his booking.totalAllowanceByType() 
// was 2 but it was already consume in the previous transaction
export function currentTotalExcessPiece() {
  return booking.isPieceBaggage()
    ? get(excessPieceCount)
    : 0;
}

// TODO ADEEL IJAZ Need to Change it to pick up excess baggage rate
export function getCurrentTotalExcess() {
  const excess = booking.isPieceBaggage()
    ? currentTotalExcessPiece()
    : currentTotalExcessWeight();

  const unit = booking.isPieceBaggage() ? PieceUnit : WeightUnit;

  return { excess, unit };
}

/** Get excess bagggage rate */
export function getExcessBaggageRate() {
   
  logger.info('get(excessPieceApproxCharges) is: ', get(excessPieceApproxCharges));
  logger.info('get(excessPieceCurrency) is: ', get(excessPieceCurrency));
  logger.info('booking.isPieceBaggage() is: ', booking.isPieceBaggage());

  return booking.isPieceBaggage()
    ? { currency: get(excessPieceCurrency) }
    : integratedCatalogue.getExcessBaggageRate(currentTotalExcessWeight());
}

/**
 * Set the weight (kg) on the current bag.
 *
 * Ignore if the bag was lifted or is settled.
 *
 * @param {number} newWeight - The new weight.
 */
export function setCurrentBagWeight(newWeight) {
  const { rawWeight, weight, settled, isLifted } = get(currentBag);

  if (settled || isLifted) {
    logger.warn(
      `Ignoring bag weight (${newWeight}kg) because bag weight already taken. ` +
      `Current Bag - weight: ${weight}, ` +
      `settled: ${settled}, isLifted: ${isLifted}.`,
    );
  } else if (numbersEqualWithTolerance(rawWeight, newWeight)) {
    logger.info(
      'Received scales weight less than tolerance. Ignoring. ' +
      ` Current Bag - weight: ${rawWeight}; new weight: ${newWeight}.`,
    );
  } else {
    // if bag weight is > 32, even if its 32.x, we will take the ceiling value
    // the bag weight error will be raised from DispatchingBag screen
    updateCurrentBag({
      ...get(currentBag),
      rawWeight,
      weight: getCeilingIfWeightGreatorThanMaxWeight(newWeight),
    });
    startSettledTimeout();
  }
}

function getCeilingIfWeightGreatorThanMaxWeight(newWeight) {
  if (Math.ceil(newWeight) > BagWeightMax) {
    return Math.ceil(newWeight);
  }

  return newWeight;
}

/**
 * Start a timer which will set the current bag as settled after
 * a certain period of time.
 */
function startSettledTimeout() {
  clearTimeout(bagSettledTimeoutId);
  const { weight } = get(currentBag);
  if (weight > 0) {
    bagSettledTimeoutId = setTimeout(updateBagSettled, BagSettledTimeout);
  }
}

/** Set that the bag has been lifted. */
export function setIsLifted() {
  const { weight, settled, isLifted } = get(currentBag);

  // if (get(applicationFlow) === ApplicationFlow.HYBRID) {
  //   updateCurrentBag({ ...get(currentBag), isLifted: true });
  //   return
  // }

  if (!settled || weight <= 0) {
    logger.warn(
      'Do not set currentBag as lifted because ' +
      'stored currentBag is not settled or its weight <= 0. ' +
      `Stored currentBag - weight: ${weight}, ` +
      `settled: ${settled}, isLifted: ${isLifted}.`,
    );
    return;
  }

  updateCurrentBag({ ...get(currentBag), isLifted: true });
}

/** Process scale's events received from CUSS platform. */
export function processScaleEvent(statusCode) {
  switch (statusCode) {
    case CussStatusCodes.BAGGAGE_ABSENT:
      processScaleBagAbsent();
      break;
    case CussStatusCodes.BAGGAGE_PRESENT:
      logger.debug(
        'Process scale-event BAGGAGE_PRESENT. Clear bagSettledTimeout.',
      );
      clearTimeout(bagSettledTimeoutId);
      break;
    default:
      logger.warn(
        `Received ${statusCode} from the scale.` +
        ' No further processing for this event.',
      );
      break;
  }
}

/** Process scale-event BAGGAGE_ABSENT. */
function processScaleBagAbsent() {
  logger.debug('Process scale-event BAGGAGE_ABSENT.');
  clearTimeout(bagSettledTimeoutId);
  const { settled } = get(currentBag);
  if (settled) {
    setIsLifted();
  } else {
    setBagAbsentNotLifted();
  }
}

/** Set that the bag is absent and is not lifted. */
function setBagAbsentNotLifted() {
  updateCurrentBag({
    ...get(currentBag),
    weight: 0,
    settled: false,
    isLifted: false,
  });
}

/** Set the bag Tag Number Prefix field  */
function setBagTagPrefix(bagTagNumberPrefix) {
  const bag = get(currentBag);
  if (!bag.bagTagID) {
    throw new Error(
      'Sanity check failed. ' +
      'Attempted to set bag tag prefix when there is no bag tag id.',
    );
  }

  updateCurrentBag({ ...get(currentBag), bagTagNumberPrefix: bagTagNumberPrefix });
}

/** Set that the bag tag of current bag is successfully activated. */
function setBagTagActivated() {
  const bag = get(currentBag);
  if (!bag.bagTagID) {
    throw new Error(
      'Sanity check failed. ' +
      'Attempted to set bag tag as activated when there is no bag tag id.',
    );
  }

  updateCurrentBag({ ...get(currentBag), bagTagActivated: true });
}

/** Set whether the baggage is an allowable extra. */
export function setIsAllowableExtra(isAllowableExtra) {
  updateCurrentBag({ ...get(currentBag), isAllowableExtra });
}

export function setInPaymentFlow(inPaymentFlow) {
  updateCurrentBag({ ...get(currentBag), inPaymentFlow });
}

export function setPaymentSuccess(isPaymentSuccess) {
  updateCurrentBag({ ...get(currentBag), isPaymentSuccess });
}

/**
 * Check whether this is the final bag to be dropped.
 *
 * @returns {boolean}
 * */
export function isFinalBag() {
  const isFinal = get(bags).length === passengerBagCounts.totalBags();
  return isFinal;
}

/** Update the current bag as settled */
function updateBagSettled() {
  const bag = get(currentBag);
  if (!bag) {
    return;
  }

  if (bag.weight <= 0) {
    logger.warn(
      'Do not update the current bag as settled because its weight <= 0.' +
      ` bag.weight (kg) = ${bag && bag.weight}.`,
    );
    return;
  }

  bag.settled = true;
  bags.set(get(bags));
  logger.info(
    `Updated the current bag as settled. bag.weight (kg) = ${bag && bag.weight
    }.`,
  );
}

/** Set the dimensions of the current bag. */
export function setCurrentBagDimensions(length, width, height) {
  updateCurrentBag({ ...get(currentBag), length, width, height });
}

/** Set the bagTagID on the current bag. */
export function setCurrentBagBagTagID(bagTagID) {
  updateCurrentBag({ ...get(currentBag), bagTagID });
}

/**
 * Return a Promise that resolves when current bag is confirmed on the scales.
 *
 * If the bag was never lifted, will resolve straight away.
 * If the bag was lifted, will wait 60 seconds for the bag,
 * timing out after 60 seconds.
 *
 * @returns {promise}
 */
export function ensureBagOnScale() {
  const bag = get(currentBag);
  const timeout = 30000; // milliseconds
  const expectedWeight = bag.weight;
  let unsubscribe = () => { };
  logger.info(
    `Verifying bag still on scale. Expecting weight: ${expectedWeight}.`,
  );
  const bagReturnedPromise = new Promise((resolve, reject) => {
    if (!bag.isLifted) {
      logger.info('Bag verified. Bag was never lifted.');
      resolve();
      return;
    }
    setBagAbsentNotLifted();
    unsubscribe = currentBag.subscribe(($bag) => {
      if ($bag.weight === expectedWeight && $bag.settled) {
        logger.info(
          `Bag verified. ` +
          `Bag on scale matches expected weight ${expectedWeight}.`,
        );
        resolve();
      }
    });
  });
  return timeoutPromise(timeout, bagReturnedPromise).finally(unsubscribe);
}
